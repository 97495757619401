import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "p-2" }
const _hoisted_2 = {
  key: 0,
  class: "mt-3 text-center"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_progress_spinner = _resolveComponent("progress-spinner")!
  const _component_app_error_message = _resolveComponent("app-error-message")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Column = _resolveComponent("Column")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_data_table = _resolveComponent("data-table")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.self.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_progress_spinner, {
            style: {"width":"30px","height":"30px"},
            strokeWidth: "5",
            animationDuration: ".5s",
            "aria-label": "Loading"
          })
        ]))
      : _createCommentVNode("", true),
    (!Object.keys(_ctx.doc).length && !_ctx.self.loading)
      ? (_openBlock(), _createBlock(_component_app_error_message, {
          key: 1,
          message: "Select a project, folder or file to view it's tags.",
          page_icon: "fa fa-tags",
          hide_page_type: "",
          hide_button: ""
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (!_ctx.self.tags.length && !_ctx.self.loading)
            ? (_openBlock(), _createBlock(_component_app_error_message, {
                key: 0,
                message: _ctx.tagMessage,
                page_icon: "fa fa-tags",
                hide_page_type: "",
                button_text: "Add New Tag",
                hide_button: 
          _ctx.doc.permission.edit != 1
        ,
                onAddNew: _ctx.addNewTag
              }, null, 8, ["message", "hide_button", "onAddNew"]))
            : _createCommentVNode("", true),
          (_ctx.self.tags.length && !_ctx.self.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_data_table, {
                  id: "table-content",
                  editingRows: _ctx.self.editingRows,
                  "onUpdate:editingRows": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.self.editingRows) = $event)),
                  value: _ctx.self.tags,
                  editMode: "row",
                  dataKey: "id",
                  tableClass: "editable-cells-table",
                  tableStyle: "min-width: 20rem",
                  ref: "tagsTable"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      field: "name",
                      header: "Tag Group",
                      style: {"width":"40%"}
                    }, {
                      editor: _withCtx(({ data, field }) => [
                        (data.id != 'new')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(data[field]), 1))
                          : (_openBlock(), _createBlock(_component_Dropdown, {
                              key: 1,
                              modelValue: _ctx.self.edit_data.name,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.edit_data.name) = $event)),
                              options: _ctx.self.all_tags,
                              optionLabel: "name",
                              optionValue: "name",
                              placeholder: "Select a Tag",
                              editable: "",
                              class: "w-full",
                              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getTagValue($event, true)))
                            }, null, 8, ["modelValue", "options"]))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "value",
                      header: "Tag",
                      style: {"width":"40%"}
                    }, {
                      editor: _withCtx(() => [
                        (_ctx.self.tag_value.data.length && !_ctx.self.tag_value.loading)
                          ? (_openBlock(), _createBlock(_component_Dropdown, {
                              key: 0,
                              modelValue: _ctx.self.edit_data.value,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.self.edit_data.value) = $event)),
                              options: _ctx.self.tag_value.data,
                              optionLabel: "name",
                              optionValue: "value",
                              editable: "",
                              class: "w-full"
                            }, null, 8, ["modelValue", "options"]))
                          : (_openBlock(), _createBlock(_component_InputText, {
                              key: 1,
                              modelValue: _ctx.self.edit_data.value,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.self.edit_data.value) = $event)),
                              disabled: 
                  _ctx.doc.permission.edit == 0
                
                            }, null, 8, ["modelValue", "disabled"]))
                      ]),
                      _: 1
                    }),
                    (_ctx.doc.permission.edit == 1)
                      ? (_openBlock(), _createBlock(_component_Column, {
                          key: 0,
                          field: "action",
                          header: "Actions",
                          style: {"width":"20%"}
                        }, {
                          body: _withCtx((slotProps) => [
                            _createElementVNode("ul", {
                              onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"])),
                              class: "action-btn"
                            }, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.self.action_buttons, (action, key) => {
                                return (_openBlock(), _createElementBlock(_Fragment, null, [
                                  (
                      _ctx.viewButton(slotProps, action.status) &&
                      _ctx.doc.permission[action.permission] == 1
                    )
                                    ? _withDirectives((_openBlock(), _createElementBlock("li", {
                                        key: key,
                                        onClick: _withModifiers(($event: any) => (action.command($event, slotProps)), ["stop"])
                                      }, [
                                        _createElementVNode("i", {
                                          class: _normalizeClass(action.icon)
                                        }, null, 2)
                                      ], 8, _hoisted_6)), [
                                        [
                                          _directive_tooltip,
                                          action.type,
                                          void 0,
                                          { bottom: true }
                                        ]
                                      ])
                                    : _createCommentVNode("", true)
                                ], 64))
                              }), 256))
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["editingRows", "value"])
              ]))
            : _createCommentVNode("", true),
          (
          _ctx.doc.permission.edit == 1
        )
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (!_ctx.self.editingRows.length && !_ctx.self.loading && _ctx.self.tags.length)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      onClick: _ctx.addNewTag,
                      class: "mt-2 button-primary",
                      icon: "fa fa-plus",
                      label: "Add New Tag"
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]))
  ]))
}