<template>
  <div class="p-2">
    <div v-if="self.loading" class="mt-3 text-center">
      <progress-spinner
        style="width: 30px; height: 30px"
        strokeWidth="5"
        animationDuration=".5s"
        aria-label="Loading"
      />
    </div>
    <app-error-message
      v-if="!Object.keys(doc).length && !self.loading"
      message="Select a project, folder or file to view it's tags."
      page_icon="fa fa-tags"
      hide_page_type
      hide_button
    />
    <div v-else>
      <app-error-message
        v-if="!self.tags.length && !self.loading"
        :message="tagMessage"
        page_icon="fa fa-tags"
        hide_page_type
        button_text="Add New Tag"
        :hide_button="
          doc.permission.edit != 1
        "
        @add-new="addNewTag"
      />
      <div v-if="self.tags.length && !self.loading">
        <data-table
          id="table-content"
          v-model:editingRows="self.editingRows"
          :value="self.tags"
          editMode="row"
          dataKey="id"
          tableClass="editable-cells-table"
          tableStyle="min-width: 20rem"
          ref="tagsTable"
        >
          <Column field="name" header="Tag Group" style="width: 40%">
            <template #editor="{ data, field }">
              <span v-if="data.id != 'new'">
                {{ data[field] }}
              </span>
              <Dropdown
                v-else
                v-model="self.edit_data.name"
                :options="self.all_tags"
                optionLabel="name"
                optionValue="name"
                placeholder="Select a Tag"
                editable
                class="w-full"
                @change="getTagValue($event, true)"
              />
            </template>
          </Column>
          <Column field="value" header="Tag" style="width: 40%">
            <template #editor>
              <Dropdown
                v-if="self.tag_value.data.length && !self.tag_value.loading"
                v-model="self.edit_data.value"
                :options="self.tag_value.data"
                optionLabel="name"
                optionValue="value"
                editable
                class="w-full"
              />
              <InputText
                v-else
                v-model="self.edit_data.value"
                :disabled="
                  doc.permission.edit == 0
                "
              />
            </template>
          </Column>
          <Column v-if="doc.permission.edit == 1" field="action" header="Actions" style="width: 20%">
            <template #body="slotProps">
              <ul @click.stop class="action-btn">
                <template v-for="(action, key) in self.action_buttons">
                  <li
                    v-if="
                      viewButton(slotProps, action.status) &&
                      doc.permission[action.permission] == 1
                    "
                    :key="key"
                    v-tooltip.bottom="action.type"
                    @click.stop="action.command($event, slotProps)"
                  >
                    <i :class="action.icon"></i>
                  </li>
                </template>
              </ul>
            </template>
          </Column>
        </data-table>
      </div>
      <template
        v-if="
          doc.permission.edit == 1
        "
      >
        <Button
          v-if="!self.editingRows.length && !self.loading && self.tags.length"
          @click="addNewTag"
          class="mt-2 button-primary"
          icon="fa fa-plus"
          label="Add New Tag"
        />
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  onMounted,
  getCurrentInstance,
  reactive,
  ref,
  watch,
  computed,
} from "vue";
export default defineComponent({
  name: "ActivitiesTags",
  props: {
    doc: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  //
  setup(props:any) {
    const appData = getCurrentInstance() as any;
    const tagsTable = ref();
    const self = reactive({
      properties: appData.appContext.config.globalProperties,
      api_url: `${props.doc.type == "project" ? "projects" : "media"}/${Object.keys(props.doc).length ? props.doc.id : ""}/tags`,
      values_api_url: "tags/value",
      tag_api_url: "tags",
      action_buttons: [
        {
          type: "Edit",
          icon: "icofont icofont-edit",
          command: (e: any, val: any) => {
            editRow(val);
          },
          status: "not_editing",
          permission: "edit",
        },
        {
          type: "Delete",
          icon: "icofont icofont-trash",
          command: (e: any, val: any) => {
            deleteTag(val);
          },
          status: "not_editing",
          permission: "manage",
        },
        {
          type: "Save",
          icon: "icofont icofont-save",
          command: () => {
            onRowEditSave();
          },
          status: "editing",
          permission: "edit",
        },
        {
          type: "Cancel",
          icon: "icofont icofont-close",
          command: (e: any, val: any) => {
            cancelEditRow(val);
          },
          status: "editing",
          permission: "edit",
        },
      ],
      tags: [] as any,
      loading: false,
      page: 1,
      pagination: {
        current_page: 1,
        items_per_page: 30,
        total_page: 0,
        total_records: 0,
      },
      editingRows: [] as any,
      all_tags: [],
      tag_value: {
        data: [],
        loading: false,
      },

      edit_data: {
        id: null,
        name: null,
        value: null,
      },
    });
    const tagMessage = computed(() => {
      return `Currently there is no Tag on this item. ${
        (props.doc.permission.edit == 1)
          ? "Click on the button below to create a tag."
          : ""
      }`;
    });
    watch(
      () => self.edit_data.name,
      (val: any) => {
        searchTag(val);
      }
    );
    watch(
      () => props.doc,
      (val: any) => {
        self.api_url = `${val.type == 'project' ? 'projects' : 'media'}/${
          Object.keys(val).length ? val.id : ''
        }/tags`;
        if (Object.keys(props.doc).length) {
          getDocumentTags(true);
        }
      }
    );
    onMounted(() => {
      if (Object.keys(props.doc).length) {
        getDocumentTags();
      }
    });
    function viewButton(row: any, status: any) {
      let view = status != 'editing';
      if (self.editingRows.length) {
        self.editingRows.forEach((e_row: any) => {
          if (e_row.id == row.data.id) {
            view = status == 'editing';
          }
        });
      }
      return view;
    }

    function getDocumentTags(reset = false) {
      if (props.doc.permission.read == 1) {
        if (reset) {
          self.page = 1;
          self.tags = [];
        }
        if (
          (self.page <= self.pagination.total_page && !self.loading) ||
          (self.page == 1 && !self.loading)
        ) {
          self.loading = true;
          self.properties.app_service.getData(
            self.api_url,
            null,
            (response: any, error: any) => {
              if (response) { 
                self.pagination = response.metadata;
                self.tags.push(...response.data);
                self.page += 1;
              }
              if (error) {
                self.properties.$toastMessage(
                  self.properties.$getErrorResponse(error)
                );
              }
              self.loading = false;
            }
          );
        }
      } else {
        self.loading = false;
      }
    }

    function searchTag(val: any) {
      if (!val) return;
      const search_values = {
        query: val,
      };
      self.properties.app_service.searchData(
        self.tag_api_url,
        null,
        search_values,
        (response: any, error: any) => {
          if (response) {
            self.all_tags = response.data;
          }
          if (error) {
            self.properties.$toastMessage(
              self.properties.$getErrorResponse(error)
            );
          }
        }
      );
    }

    function getTagValue(val: any, reset = false) {
      if (reset) {
        self.tag_value.data = [];
      }
      const search_data = {
        key: val.value
          ? val.value
          : val.data
          ? val.data.name
            ? val.data.name
            : val.data
          : "",
      };
      if (!search_data.key) return;
      self.tag_value.loading = true;
      self.properties.app_service.searchData(
        self.values_api_url,
        null,
        search_data,
        (response: any, error: any) => {
          if (response) {
            self.tag_value.data = response.data.map((data: any) => {
              return {
                name: data.value,
                value: data.value,
              };
            });
          }
          if (error) {
            self.properties.$toastMessage(
              self.properties.$getErrorResponse(error)
            );
          }
          self.tag_value.loading = false;
        }
      );
    }

    function addNewTag() {
      const data = {
        id: 'new',
        name: null,
        value: null,
      } as any;
      self.tags.push(data);
      editRow({ data: data }, false);
    }

    function editRow(val: any, loadValue = true) {
      self.editingRows = [] as any;
      self.edit_data = {
        id: val.data.id,
        name: val.data.name,
        value: val.data.value,
      };
      self.editingRows.push(val.data);
      if (loadValue) {
        getTagValue(val, true);
      }
    }

    function cancelEditRow(val: any) {
      self.editingRows = [] as any;
      if (val.data.id == 'new') {
        self.tags = self.tags.filter((tag: any) => tag.id != val.data.id);
      }
    }

    function onRowEditSave() {
      if (self.edit_data.name == null || self.edit_data.value == null)
        return self.properties.$toastMessage({
          data: {
            message: 'Please add both Tag Group & Tag',
          },
        });
      const data = {
        tag_name: self.edit_data.name,
        value: self.edit_data.value,
      };
      if (self.edit_data.id == 'new') {
        self.properties.app_service.createData(
          self.api_url,
          data,
          (response: any, error: any) => {
            if (response) {
              getDocumentTags(true);
              self.properties.$toastMessage(response);
              cancelEditRow({ data: self.edit_data });
            }
            if (error) {
              self.properties.$toastMessage(
                self.properties.$getErrorResponse(error)
              );
            }
          }
        );
      } else {
        self.properties.app_service.updateData(
          self.api_url,
          data,
          null,
          (response: any, error: any) => {
            if (response) {
              cancelEditRow({ data: self.edit_data });
              getDocumentTags(true);
              self.properties.$toastMessage(response);
            }
            if (error) {
              self.properties.$toastMessage(
                self.properties.$getErrorResponse(error)
              );
            }
          },
          null,
          true
        );
      }
    }

    function deleteTag(row: any) {
      self.properties.$confirm.require({
        group: "confirmDialog",
        message: "Are you sure you want to remove tag from document ?",
        header: "Remove Tag",
        position: "top",
        rejectLabel: "Cancel",
        acceptLabel: "Ok",
        accept: () => {
          self.properties.app_service.deleteData(
            `${props.doc.type == 'project' ? 'projects' : 'media'}/${props.doc.id}/tags/${row.data.id}`,
            null,
            (response: any, error: any) => {
              if (response) {
                self.properties.$toastMessage(response);
                getDocumentTags(true);
              }
              if (error) {
                self.properties.$toastMessage(
                  self.properties.$getErrorResponse(error)
                );
              }
            }
          );
        },
      });
    }

    return {
      tagMessage,
      appData,
      tagsTable,
      self,
      viewButton,
      deleteTag,
      getDocumentTags,
      addNewTag,
      onRowEditSave,
      searchTag,
      getTagValue,
    };
  },
});
</script>