<template>
  <div>
    <div id="document-grid">
      <div
        v-for="(documents, index) in componentData.documents"
        class="grid p-3"
        :key="index"
      >
        <div
          v-for="(document, sub_index) in documents"
          :draggable="properties.$getContentType(document) != 'project' && drag_selector"
          :key="sub_index"
          :disabled="document.renaming || document.new || !drag_selector"
          class="col col-2 cursor-pointer document mx-2 border-2 border-transparent"
          :class="[
            selected_documents.includes(document) ||
            selected_document.id == document.id ||
            highlight_doc.id == document.id ||
            dragged_over_doc.id == document.id
              ? 'p-highlight'
              : '',
            (moving_doc.type == 'cut' && moving_doc.detail.id == document.id) ||
            document.text_extraction_pending ||
            permission == 'trash'
              ? 'opacity-60'
              : '',
            disable_select ? 'pt-5' : '',
            dragged_over_doc.id == document.id
              ? 'border-dashed border-2 border-primary'
              : '',
          ]"
          @contextmenu.prevent="
            viewContextMenu({ data: document, originalEvent: $event })
          "
          @click.prevent="
            document.renaming || document.new ? null : onItemClick($event, document)
          "
          @dragstart="drag_selector ? $emit('dragged-doc', $event, document) : null"
          @dragend="$emit('stop-dragged', $event)"
          @dragover="$emit('drag-over-sub', $event, document)"
          @dragleave="$emit('drag-leave-sub', $event)"
          @dragenter="$emit('drag-over-sub', $event, document)"
          @drop.stop.prevent="$emit('upload-on-document', $event, document)"
          @mouseover="
            document.renaming || document.new ? null : togglePopover($event, document)
          "
          @mouseleave="
            document.renaming || document.new
              ? null
              : togglePopover($event, document, false)
          "
        >
          <OverlayPanel :ref="`popOver${document.id}`" appendTo="body">
            <ul>
              <li
                v-if="
                  view_parent && document.parent_project && document.type != 'project'
                "
                class="max-w-26rem"
              >
                Parent Project: {{ document.parent_project.name }}
              </li>
              <li
                class="max-w-26rem"
              >
                Name: {{ document.name }}
              </li>
              <li v-if="!disable_select">
                Created at: {{ properties.$dayMonthDateYear(document.created_at) }}
              </li>
              <li v-if="document.metadata && !disable_select">
                Size:
                {{
                  properties.$getFileSize(
                    properties.app_service.parseJSON(document.metadata).size
                  )
                }}
              </li>
              <li v-if="!disable_select">
                Owner: {{ document.owner.name ? document.owner.name : document.owner }}
              </li>
            </ul>
          </OverlayPanel>
          <Checkbox
            @click.stop
            v-model="componentData.selected_documents"
            :inputId="`${document.slug}-${document.id}`"
            :name="document.slug"
            :value="document"
            v-if="!disable_select"
          />
          <div :id="`${document.id}`" class="selectable-doc text-center">
            <div class="document-icon mb-1" :class="properties.$getContentType(document)">
              <span v-if="properties.$getContentType(document) == 'image'">
                <Image
                  width="32"
                  :src="properties.$getFullPath(document.path)"
                  :alt="document.name"
                />
              </span>
              <span v-else v-html="properties.$getIcon(document)"></span>
              <span v-if="document.shared">
                <i class="overlap-icon shared-icon fas fa-share-alt" />
              </span>
              <span v-if="document.starred">
                <i class="overlap-icon starred-icon fas fa-star" />
              </span>
              <span v-if="document.pinned">
                <i class="overlap-icon pinned-icon fa-solid fa-thumbtack" />
              </span>
            </div>
            <input
              v-if="document.renaming || document.new"
              v-model="document.newName"
              :ref="`docName${document.id}`"
              class="p-inputtext p-component p-filled"
              @focus="$emit('update:disabled_selection', true)"
              @blur="$emit('update:disabled_selection', false)"
              @keypress.enter="
                document.new
                  ? $emit('create-document', document.newName)
                  : $emit('update-document', document.newName)
              "
            />
            <span
              v-else
              class="white-space-nowrap overflow-hidden text-overflow-ellipsis"
              :class="[activities_view ? 'max-w-10rem' : 'max-w-12rem']"
            >
              {{ document.name }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="card flex justify-content-center"
        v-if="loading" 
      >
        <ProgressSpinner
          style="width: 30px; height: 30px"
          strokeWidth="5"
          animationDuration=".5s"
          aria-label="Loading"
        />
      </div>
      <ContextMenu
        ref="gridContext"
        @before-show="$emit('update-menu')"
        :model="context_items"
      >
        <template #item="{ label, item, props }">
          <router-link v-if="item.to" v-slot="routerProps" :to="item.to" custom>
            <a :href="routerProps.href" v-bind="props.action">
              <span v-bind="props.icon" />
              <span v-bind="props.label">{{ label }}</span>
            </a>
          </router-link>
          <a v-else :href="item.url" :target="item.target" v-bind="props.action">
            <span v-bind="props.icon" />
            <span v-bind="props.label">{{ label }}</span>
          </a>
        </template>
      </ContextMenu>
      <InfiniteLoader :target="target" @loadMore="$emit('load-more-docs')" />
    </div>
    <ProgressSpinner
      v-if="self.updating_doc"
      style="width: 50px; height: 50px"
      strokeWidth="8"
      fill="transparent"
      animationDuration=".5s"
      aria-label="Custom ProgressSpinner"
    />
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  onMounted,
  getCurrentInstance,
  reactive,
  watch,
  ref,
} from "vue";
import DragSelect from "dragselect";
export default defineComponent({
  name: "DocGrid",
  props: {
    target: {
      type: String,
      default: null
    },
    disable_select: {
      type: Boolean,
      default: false,
    },
    selected_documents: {
      type: Array,
      default: () => [] as any,
    } as any,
    selected_document: {
      type: Object,
      default: () => {
        return {};
      },
    },
    context_items: {
      type: Array,
      default: () => [] as any,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    activities_view: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [] as any,
    },
    moving_doc: {
      type: Object,
      default: () => {
        return {
          detail: {},
          type: "",
        };
      },
    },
    view_dropzone: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: String,
    },
    drag_selector: {
      type: Boolean,
      default: true,
    },
    create: {
      type: Object,
    } as any,
    highlight_doc: {
      type: Object,
      default: () => {
        return {
          type: null,
          id: null,
        } as any;
      },
    } as any,
    dragged_over_doc: {
      type: Object,
      default: () => {
        return {} as any;
      },
    } as any,
    update_listing: {
      type: Boolean,
      default: false,
    },
    disabled_selection: {
      type: Boolean,
      default: false,
    },
    view_parent: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "load-more-docs",
    "load-doc-detail",
    "update:selected_documents",
    "update:selected_document",
    "update:update_listing",
    "update:disabled_selection",
    "update-document",
    "create-document",
    "update-menu",
    "dragged-doc",
    "stop-dragged",
    "drag-over-sub",
    "drag-leave-sub",
    "upload-on-document",
    "update-name",
  ],
  setup(props: any, { emit }) {
    const self: any = getCurrentInstance();
    const properties: any = self.appContext.config.globalProperties;
    const gridContext = ref();
    const debounce = properties.$debounce();
    const componentData = reactive({
      selected_documents: [] as any,
      selected_doc_ids: [] as any,
      selected_document: {} as any,
      documents: [] as any,
      drag_selector: {} as any,
    });

    onMounted(() => {
      getDocData();
      setTimeout(() => {
        groupDocItem(5);
      }, 500);
      if (props.disable_select) {
        properties.$removeDragSelection();
        componentData.drag_selector = {};
      }
    });

    function getDocData() {
      componentData.selected_documents = props.selected_documents;
      componentData.selected_document = props.selected_document;
    }

    function updateDocument(data: any) {
      debounce(() => {
        if (data.new) {
          emit("create-document", data.newName);
        } else if (data.renaming) {
          emit("update-document", data.newName);
        }
      });
    }

    function groupDocItem(n: any) {
      self.updating_doc = true;
      componentData.documents = [] as any;
      componentData.drag_selector = {};
      for (let i = 0, j = 0; i < props.items.length; i++) {
        if (i >= n && i % n === 0) j++;
        componentData.documents[j] = componentData.documents[j] || ([] as any);
        componentData.documents[j].push(props.items[i]);
      }
      emit("update:update_listing", false);
      getSelectionData();
      self.updating_doc = false;
    }

    function getSelectionData() {
      setTimeout(() => {
        if (
          document.getElementById("document-grid") &&
          document.querySelectorAll(".selectable-doc").length >= 1 &&
          props.drag_selector &&
          props.items.length &&
          !props.loading &&
          props.drag_selector
        ) {
          if (!Object.keys(componentData.drag_selector).length) {
            if (!props.disable_select) {
              componentData.drag_selector = new DragSelect({
                selectables: document.querySelectorAll(".selectable-doc") as any,
                area: document.getElementById("document-grid") as any,
                draggability: false,
                multiSelectToggling: true,
              });
            }
          }
          componentData.drag_selector.subscribe("DS:end", (select: any) => {
            if (select.items) {
              getSelectedItems(select.items);
            }
          });
        }
      }, 1500);
    }

    function getSelectedItems(items: any) {
      if (props.drag_selector) {
        items.forEach((item: any) => {
          props.items.forEach((doc: any) => {
            if (doc.id == item.getAttribute("id")) {
              updateSelectedDocs(doc);
            }
          });
        });
        upDateSelectedItems();
      }
    }

    function updateSelectedDocs(doc: any) {
      if (componentData.selected_documents.length) {
        if (!componentData.selected_doc_ids.includes(doc.id)) {
          componentData.selected_documents.push(doc);
          componentData.selected_doc_ids.push(doc.id);
        }
      } else {
        componentData.selected_documents.push(doc);
        componentData.selected_doc_ids.push(doc.id);
      }
    }

    function upDateSelectedItems() {
      if (Object.keys(componentData.drag_selector).length) {
        const selection: any = {
          selectables: componentData.drag_selector.getSelectables(),
          selected_items: componentData.drag_selector.getSelection(),
          filtered_selected_items: [],
        };
        componentData.drag_selector.clearSelection();
        selection.filtered_selected_items = selection.selectables.filter((item: any) =>
          componentData.selected_doc_ids.includes(Number(item.id))
        );
        componentData.drag_selector.addSelection(selection.filtered_selected_items);
      }
    }

    function viewContextMenu(e: any) {
      if (props.create.new || e.data.renaming) return;
      gridContext.value.hide();
      setTimeout(() => {
        emit("update-menu");
        emit("update:selected_document", {});
        emit("update:selected_document", e.data);
        gridContext.value.show(e.originalEvent);
      }, 10);
    }

    function onItemClick(e: any, doc: any) {
      if (e.ctrlKey || e.shiftKey || e.metaKey || doc.renaming || doc.new) {
        e.preventDefault();
        return;
      }
      emit("load-doc-detail", { data: doc });
    }

    function togglePopover(e: any, doc: any, view = true) {
      const popOverData: any = self.refs[`popOver${doc.id}`];
      if (view) {
        popOverData[0].show(e);
      } else {
        popOverData[0].hide();
      }
    }

    watch(
      () => props.selected_documents,
      (data: any) => {
        componentData.selected_doc_ids = data.map((doc: any) => {
          return doc.id;
        });
        componentData.selected_documents = data;
        upDateSelectedItems();
      }
    );

    watch(
      () => props.selected_document,
      (data: any) => {
        componentData.selected_document = data;
      }
    );

    watch(
      () => componentData.selected_documents,
      (data: any) => {
        emit("update:selected_documents", data);
      }
    );

    watch(
      () => componentData.selected_document,
      (data: any) => {
        emit("update:selected_document", data);
      }
    );

    watch(
      () => props.items,
      () => {
        groupDocItem(5);
      }
    );

    watch(
      () => props.create,
      (val: any) => {
        if (val.new) {
          groupDocItem(5);
        }
      }
    );

    watch(
      () => props.update_listing,
      (val: any) => {
        if (val) {
          groupDocItem(5);
        }
      }
    );

    watch(
      () => props.disable_select,
      (val: any) => {
        if (val) {
          properties.$removeDragSelection();
          componentData.drag_selector = {};
        }
      }
    );

    watch(
      () => props.drag_selector,
      (val: any) => {
        if (val) {
          properties.$removeDragSelection();
          componentData.drag_selector = {};
        } else {
          getSelectionData();
        }
      }
    );

    return {
      self,
      properties,
      gridContext,
      componentData,
      debounce,
      getDocData,
      groupDocItem,
      viewContextMenu,
      onItemClick,
      togglePopover,
      updateDocument,
    };
  },
});
</script>
