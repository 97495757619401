<template>
  <div>
    <AppToolbar
      v-model:current_view="self.current_view"
      v-model:current_sort="self.current_sort"
      v-model:view_activities="self.activities.view"
      v-model:selected_items="self.selectedDocuments"
      permission="media"
      search_placeholder="Search Starred"
      :breadcrumb_home="self.breadcrumbHome"
      :breadcrumb_items="self.breadcrumbItems"
      :options="self.toolbarOptions"
      :filter="self.filterOptions"
      :add_options="self.default_context_menu"
      :type_options="self.filterTypeOptions"
      :loading="self.loading"
      :suggestions="self.suggestions"
      @create-new="createNew"
      @reload-data="reloadAllData"
      @download-item="downloadSelectedDocuments"
      @submit-filter-data="searchDocuments"
      @get-suggestions="getFilterSuggestions"
    />
    <div class="the-content">
      <div class="grid">
        <div
          :class="[self.activities.view ? 'col-8' : 'col-12 width-transaction']"
          class="p-0 relative top-0"
        >
          <DocUploader
            ref="docUploader"
            v-model:view_dropzone="self.view_dropzone"
            permission="media"
            :is_starred_page="true"
            :is_root="self.is_root"
            :parent="self.parent_doc"
            :selected_document="self.selected_document"
            :dragged_selected_document="self.dragged_selected_document"
            :items="self.documents"
            :loading="self.loading_docs"
            :canceled_upload="self.canceled_upload"
            @media-uploaded="onUploadComplete"
            @view-default-context="viewDefaultContext"
          >
            <template #documents>
              <div class="vault-docs" v-if="self.documents.length">
                <DocTableView
                  v-show="self.current_view == 'list'"
                  ref="docTable"
                  v-model:selected_documents="self.selectedDocuments"
                  v-model:selected_document="self.selected_document"
                  v-model:disabled_selection="self.disabled_selection"
                  permission="media"
                  :context_items="self.menu_items"
                  :loading="self.loading_docs"
                  :items="self.documents"
                  :fields="self.table_fields"
                  :pagination="self.pagination"
                  :activities_view="self.activities.view"
                  :clickable="true"
                  :drag_selector="self.drag_selector"
                  :moving_doc="self.moving_doc"
                  :highlight_doc="self.highlight_doc"
                  @load-more-docs="getDocument"
                  @load-doc-detail="getDocumentDetail"
                  @update-document="updateDocument"
                  @update-name="updateDocName"
                  @create-document="createNewDocument"
                  @update-menu="
                    getMenuItems();
                    self.highlight_doc = {};
                  "
                  @upload-on-document="uploadOnDocument"
                  @drag-over-sub="dragedOverDocument"
                  @drag-leave-sub="dragedLeaveDocument"
                  @dragged-doc="draggedDocument"
                  @stop-dragged="stopDrag"
                />
                <DocGrid
                  v-show="self.current_view == 'grid'"
                  ref="docGrid"
                  v-model:selected_documents="self.selectedDocuments"
                  v-model:selected_document="self.selected_document"
                  v-model:update_listing="self.update_listing"
                  v-model:disabled_selection="self.disabled_selection"
                  permission="media"
                  :context_items="self.menu_items"
                  :loading="self.loading_docs"
                  :items="self.documents"
                  :activities_view="self.activities.view"
                  :drag_selector="self.drag_selector"
                  :moving_doc="self.moving_doc"
                  :create="self.create"
                  :highlight_doc="self.highlight_doc"
                  :dragged_over_doc="self.dragged_selected_document"
                  @update-menu="
                    getMenuItems();
                    self.highlight_doc = {};
                  "
                  @load-more-docs="getDocument"
                  @load-doc-detail="getDocumentDetail"
                  @update-document="updateDocument"
                  @update-name="updateDocName"
                  @create-document="createNewDocument"
                  @upload-on-document="uploadOnDocument"
                  @drag-over-sub="dragedOverDocument"
                  @drag-leave-sub="dragedLeaveDocument"
                  @dragged-doc="draggedDocument"
                  @stop-dragged="stopDrag"
                />
              </div>
              <div v-if="self.loading_docs" class="text-center my-4">
                <ProgressSpinner
                  style="width: 20px; height: 20px"
                  strokeWidth="5"
                  animationDuration=".5s"
                  aria-label="Loading"
                />
              </div>
            </template>
          </DocUploader>
        </div>
        <div class="col-4" @contextmenu.stop v-if="self.activities.view">
          <Activities
            v-model:active_tab="self.activities.active_tab"
            :doc="self.selected_document"
            :activities="self.activities.data"
            :loading="self.activities.loading"
            @open-share-modal="self.share_modal = true"
            @load-more-activities="getActivities"
            @view-activity-detail="viewActivityDetail"
            :permission="self.selected_document.permission"
          />
        </div>
      </div>
      <Dialog
        v-model:visible="self.preview_img"
        modal
        :style="{ width: '90em', height: '90em' }"
        maximizable
      >
        <template #header>
          {{ self.selected_document.name }}
        </template>
        <div
          class="flex justify-content-center align-items-center flex-wrap text-center"
        >
          <div class="dialog-image">
            <Image
              :src="self.properties.$getFullPath(self.selected_document.path)"
              :alt="self.selected_document.name"
            />
          </div>
        </div>
      </Dialog>
      <SharedModal
        v-if="self.share_modal"
        v-model:view_modal="self.share_modal"
        permission="media"
        :doc="self.selected_document"
        @shared-document="updateShared"
        @share-revoked="revokedShared"
      />
      <AnalyticsModal
        v-if="self.analytic_modal"
        v-model:view_modal="self.analytic_modal"
        :doc="self.selected_document"
        @preview-document="previewDocument"
      />
      <FolderTemplateModal
        v-if="self.folder_template_modal"
        v-model:view_modal="self.folder_template_modal"
        :doc="self.selected_document"
        @used-template="reloadAllData"
      />
      <OwnerModal
        v-if="self.owner_modal"
        v-model:view_modal="self.owner_modal"
        :doc="self.selected_document"
      />
      <DownloadModal
        v-model:view_modal="self.download.modal"
        :message="self.download.message"
      />
      <PDFViewer
        v-if="self.preview_pdf"
        :preview="self.preview_pdf"
        :pdf_page="self.pdf_page"
        @close-pdf="
          self.preview_pdf = false;
          self.pdf_page.view = false;
        "
        :pdf_url="
          self.selected_document.proxy_path
            ? self.selected_document.proxy_path
            : self.selected_document.path
        "
        :doc="self.selected_document"
        :user_session="self.user_session"
      />
      <Dialog
        v-model:visible="self.moving_doc.view"
        modal
        :style="{ width: '100em', height: '100em' }"
        maximizable
        id="move-doc"
      >
        <template #header>
          <div id="sub-header" class="relative">
            <Breadcrumb
              v-if="self.move_data.breadcrumbs.length < 3"
              :home="self.move_data.breadcrumbHome"
              :model="self.move_data.breadcrumbs"
            >
              <template #item="{ label, item, props }">
                <router-link v-if="item.to" :to="item.to" @click="item.command">
                  <span v-bind="props.action">
                    <span v-bind="props.icon" />
                    <span v-bind="props.label">{{ label }}</span>
                  </span>
                </router-link>
                <a
                  v-else
                  :href="item.url"
                  :target="item.target"
                  v-bind="props.action"
                  @click="item.command"
                >
                  <span v-if="item.icon" v-bind="props.icon" />
                  <span v-bind="props.label">{{ label }}</span>
                </a>
              </template>
            </Breadcrumb>
            <div v-else class="flex flex-row flex-wrap">
              <Breadcrumb
                :home="self.move_data.breadcrumbHome"
                :model="self.move_data.breadcrumbs.slice(0, 1)"
              >
                <template #item="{ label, item, props }">
                  <router-link
                    v-if="item.to"
                    :to="item.to"
                    @click="item.command"
                  >
                    <span v-bind="props.action">
                      <span v-bind="props.icon" />
                      <span v-bind="props.label">{{ label }}</span>
                    </span>
                  </router-link>
                  <a
                    v-else
                    :href="item.url"
                    :target="item.target"
                    v-bind="props.action"
                  >
                    <span v-if="item.icon" v-bind="props.icon" />
                    <span v-bind="props.label">{{ label }}</span>
                  </a>
                </template>
              </Breadcrumb>
              <div class="flex align-items-center breadcrumb-data">
                <i class="pi pi-chevron-right px-2"></i>
                <Button
                  type="button"
                  text
                  icon="pi pi-ellipsis-h"
                  class="breadcrumb-overflow p-0 w-auto"
                  @click="togglemoveToBreadcrumbItem"
                />
                <OverlayPanel
                  class="breadcrumb-panel"
                  ref="moveTobreadcrumbItem"
                >
                  <Listbox
                    :options="
                      self.move_data.breadcrumbs.slice(
                        1,
                        self.move_data.breadcrumbs.length - 1
                      )
                    "
                    class="w-full md:w-14rem"
                  >
                    <template #option="slotProps">
                      <div
                        class="w-100 h-100"
                        @click="togglemoveToBreadcrumbItem"
                      >
                        <router-link
                          v-if="slotProps.option.to"
                          :to="slotProps.option.to"
                          @click="slotProps.option.command"
                        >
                          <div class="flex align-items-center">
                            <div>{{ slotProps.option.label }}</div>
                          </div>
                        </router-link>
                        <a
                          v-else
                          :href="slotProps.option.url"
                          :target="slotProps.option.target"
                          @click="slotProps.option.command"
                        >
                          <span>{{ slotProps.option.label }}</span>
                        </a>
                      </div>
                    </template>
                  </Listbox>
                </OverlayPanel>
                <div class="flex align-items-center">
                  <i class="pi pi-chevron-right px-2"></i>
                  <ul>
                    <li class="text-primary">
                      {{
                        self.move_data.breadcrumbs[
                          self.move_data.breadcrumbs.length - 1
                        ].label
                      }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
        <TableView
          :stripedRows="false"
          permission_type="media"
          v-model:selected_document="self.move_data.selected_doc"
          :loading="self.move_data.loading"
          :items="self.move_data.data"
          :fields="self.move_data.fields"
          :pagination="self.move_data.pagination"
          :disable_select="true"
          :actions="self.move_data.action_buttons"
          :highlight_doc="self.move_data.selected_doc"
        >
          <template #col-data="{ data }">
            <div
              :class="{
                'opacity-60': self.moving_doc.detail.id == data.data.id,
              }"
              :disabled="self.moving_doc.detail.id == data.data.id"
              class="flex justify-content-start flex-wrap"
              @click="self.move_data.selected_doc = data.data"
            >
              <div
                class="flex align-items-center justify-content-center document-icon mr-1"
                :class="self.properties.$getContentType(data.data)"
              >
                <span
                  v-if="self.properties.$getContentType(data.data) == 'image'"
                >
                  <Image
                    width="32"
                    :src="self.properties.$getFullPath(data.data.path)"
                    class="flex align-content-center flex-wrap"
                    :alt="data.value"
                  />
                </span>
                <span
                  v-else
                  v-html="self.properties.$getIcon(data.data)"
                ></span>
              </div>
              <div class="flex align-items-center justify-content-start w-9">
                <span>
                  {{ data.value }}
                </span>
              </div>
            </div>
          </template>
          <template #action-buttons="{ data }">
            <ul
              v-show="
                self.moving_doc.detail.id != data.id && data.type != 'file'
              "
              @click.stop
              class="action-btn"
            >
              <li
                v-for="action in self.move_data.action_buttons"
                :key="action.type"
                v-tooltip.bottom="action.type"
                @click.stop="action.command(data)"
              >
                <i :class="action.icon"></i>
              </li>
            </ul>
          </template>
        </TableView>
        <div
          v-if="
            Object.keys(self.move_data.selected_doc).length &&
            self.moving_doc.detail.id != self.move_data.selected_doc.id &&
            self.move_data.selected_doc.type != 'file'
          "
          class="flex justify-content-end"
        >
          <Button
            class="mt-2 button-primary"
            label="Move Here"
            @click.stop="moveDocument"
          />
        </div>
      </Dialog>
      <ContextMenu ref="defaultContext" :model="self.default_context_menu">
        <template #item="{ label, item, props }">
          <router-link v-if="item.to" v-slot="routerProps" :to="item.to" custom>
            <a :href="routerProps.href" v-bind="props.action">
              <span v-bind="props.icon" />
              <span v-bind="props.label">{{ label }}</span>
            </a>
          </router-link>
          <a
            v-else
            :href="item.url"
            :target="item.target"
            v-bind="props.action"
          >
            <span v-bind="props.icon" />
            <span v-bind="props.label">{{ label }}</span>
          </a>
        </template>
      </ContextMenu>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  onBeforeUnmount,
  reactive,
  capitalize,
  watch,
  ref,
} from "vue";
import DocTableView from "../my-vault/components/tableView.vue";
import DocGrid from "../my-vault/components/gridView.vue";
import DocUploader from "../my-vault/components/docUploader.vue";
import DownloadModal from "@/components/common/download/modal.vue";
import SharedModal from "@/components/common/share/index.vue";
import AnalyticsModal from "@/components/common/analytics/index.vue";
import FolderTemplateModal from "@/components/common/folder-template/index.vue";
import OwnerModal from "@/components/common/owner/index.vue";
import PDFViewer from "@/components/common/pdf/pdfViewer.vue";
import Activities from "@/components/common/activities/index.vue";
import { v4 as uuidv4 } from "uuid";
export default defineComponent({
  name: "StarredDocs",
  components: {
    DocTableView,
    DocGrid,
    PDFViewer,
    Activities,
    SharedModal,
    AnalyticsModal,
    FolderTemplateModal,
    OwnerModal,
    DocUploader,
    DownloadModal,
  },
  setup() {
    const docTable = ref();
    const docGrid = ref();
    const docUploader = ref();
    const defaultContext = ref();
    const moveTobreadcrumbItem = ref();
    const appData: any = getCurrentInstance();
    const appContext: any = appData.appContext;
    const self = reactive({
      properties: appContext.config.globalProperties,
      api_urls: {
        my_vault: "starred",
        project: "projects",
        media: "media",
        activity: "activities",
        share: "share",
        breadcrumb: "breadcrumbs",
        analytics: "document/analytics",
      },
      breadcrumbHome: {
        icon: "fa fa-star mr-1",
        label: "Starred",
        to: { name: "starredDocs" },
      } as any,
      breadcrumbItems: [] as any,
      current_view: "list",
      current_sort: "A_Z",
      sort_by_query: false,
      user_session: uuidv4(),
      create: {
        id: "new",
        new: false,
        type: "",
        name: "New",
        newName: "New",
      },
      preview_pdf: false,
      pdf_page: {
        view: false,
        page: 1,
      },
      preview_img: false,
      toolbarOptions: {
        filter: true,
        activities: true,
        refresh: true,
        add: false,
        add_dropdown: true,
        view_data: true,
        view_sort:true
      },
      filterOptions: {
        status: false,
        date: true,
        type: true,
        owner: true,
      },
      filterTypeOptions: ["project", "folder", "file"],
      activities: {
        view: false,
        active_tab: 0,
        data: [] as any,
        page: 1,
        pagination: {
          per_page: 15,
          total_records: 30,
          current_page: 1,
          total_page: 1,
        },
        loading: false,
      },
      loading: false,
      search_view: false,
      search_values: {} as any,
      selected_document: {} as any,
      dragged_selected_document: {} as any,
      dragged_doc: {} as any,
      parent_doc: {} as any,
      drag_selector: true,
      nested_path: "",
      selectedDocuments: [] as any,
      view_dropzone: false,
      loading_docs: true,
      documents: [] as any,
      share_modal: false,
      owner_modal: false,
      folder_template_modal: false,
      analytic_modal: false,
      download: {
        modal: false,
        message: "",
      },
      page: 1,
      pagination: {
        per_page: 15,
        total_records: 30,
        current_page: 1,
        total_page: 1,
        breadcrumbs: [],
      },
      menu_items: [] as any,
      default_context_menu: [] as any,
      table_fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
          style: "width:30%",
        },
        {
          key: "created_at",
          label: "Created At",
          sortable: true,
          format: (val: any) => {
            return appContext.config.globalProperties.$dayMonthDateYear(val);
          },
        },
        {
          key: "metadata",
          label: "Size",
          sortable: false,
          format: (val: any) => {
            const data = val
              ? appContext.config.globalProperties.app_service.parseJSON(val)
              : null;
            return data
              ? appContext.config.globalProperties.$getFileSize(data.size)
              : "-";
          },
        },
        {
          key: "owner",
          label: "Owner",
          sortable: true,
          format: (val: any) => {
            return val ? (val.name ? val.name : val) : null;
          },
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
          format: (val: any) => {
            return capitalize(val);
          },
        },
      ],
      is_root: true,
      moving_doc: {
        detail: {} as any,
        type: "",
        view: false,
      },
      move_data: {
        loading: false,
        data: [] as any,
        fields: [
          {
            key: "name",
            label: "Name",
            sortable: true,
            style: "width:90%",
          },
        ],
        selected_doc: {} as any,
        action_buttons: [
          {
            type: "View Information",
            icon: "pi pi-angle-right",
            permission: "read",
            command: (val: any) => {
              getMoveListingData(val, true);
            },
          },
        ],
        breadcrumbHome: {
          icon: "icofont icofont-ui-folder mr-1",
          label: "My Vault",
          command: () => {
            getMoveListingData({}, true, true);
          },
        } as any,
        breadcrumbs: [] as any,
        page: 1,
        pagination: {
          per_page: 15,
          total_records: 30,
          current_page: 1,
          total_page: 1,
          breadcrumbs: [],
        },
      },
      canceled_upload: 0,
      processing_download: false,
      change_activities: true,
      suggestions: {
        data: [] as any,
        loading: false,
        page: 1,
        pagination: {
          current_page: 1,
          items_per_page: 0,
          total_page: 0,
          total_records: 0,
        },
      },
      highlight_doc: {} as any,
      update_listing: false,
      disabled_selection: false,
    });
    onMounted(() => {
      getQuery();
      self.current_view = self.properties.$store.getters[
        "system/getUserPreference"
      ]
        ? self.properties.$store.getters["system/getUserPreference"].view
        : "list";
      self.current_sort = self.properties.$store.getters[
        "system/getUserPreference"
      ]
        ? self.properties.$store.getters["system/getUserPreference"].sorting
          ? self.properties.$store.getters["system/getUserPreference"].sorting
          : "A_Z"
        : "A_Z";
      triggerKeyPress();
      getDefaultMenu();
      getMenuItems();
      getActivities(true);
      openSocketConnection();
      addClickEvent();
      const params: any = self.properties.$route.params;
      if (Object.keys(params).length) {
        getDocumentByParams(params);
        return;
      } else {
        getDocument();
      }
    });
    onBeforeUnmount(() => {
      removeClickEvent();
      document.removeEventListener("keydown", () => {});
    });

    watch(
      () => self.current_sort,
      () => {
        if (!self.loading_docs) {
          self.sort_by_query = true;
          reloadAllData();
        }
      }
    );

    watch(
      () => self.selected_document,
      () => {
        getMenuItems();
      }
    );
    watch(
      () => self.is_root,
      () => {
        getDefaultMenu();
      }
    );

    watch(
      () => self.properties.$route.params,
      (val: object) => {
        if (Object.keys(val).length) return getDocumentByParams(val);
        self.is_root = true;
        self.parent_doc = {};
        self.breadcrumbItems = [];
        reloadAllData();
      }
    );
    watch(
      () => self.moving_doc.type,
      (val: string) => {
        if (val == "cut") {
          updateMenu();
        }
      }
    );

    watch(
      () => self.moving_doc.view,
      (val: boolean) => {
        if (!val) {
          self.move_data.selected_doc = {};
        }
      }
    );

    watch(
      () => self.view_dropzone,
      (val: boolean) => {
        if (val) {
          self.drag_selector = false;
        } else {
          setTimeout(() => {
            self.drag_selector = true;
          }, 300);
        }
      }
    );

    function getQuery() {
      const query = self.properties.$route.query || {};
      if (Object.keys(query).length) {
        if (query.ref) {
          const query_ref = query.ref.split("-");
          self.highlight_doc = {
            type: query_ref[0],
            id: query_ref[1],
          };
        }
      }
    }

    function triggerKeyPress() {
      document.addEventListener("keydown", (evt: any) => {
        // document.body.style.userSelect = "none";
        if (evt.keyCode === 27) {
          cancelCreateNew();
        }
        // if (!self.disabled_selection) {
        //   if (evt.ctrlKey || evt.metaKey) {
        //     if (evt.keyCode == 65 || evt.keyCode == 97) {
        //       self.selectedDocuments = self.documents;
        //       setTimeout(() => {
        //         const toolbar_input: any = document.querySelector(".p-toolbar .p-inputtext");
        //         // document.body.style.userSelect = null as any;
        //         toolbar_input.click();
        //       }, 1000);
        //     }
        //   }
        // }
      });
    }

    function cancelCreateNew() {
      self.selectedDocuments = self.selectedDocuments.filter(
        (doc: any) => !doc.new
      );
      self.drag_selector = true;
      self.create = {
        id: "new",
        new: false,
        type: "",
        name: "New",
        newName: "New",
      };
      self.documents.forEach((doc: any) => {
        if (doc.new) {
          removeDocument(doc);
        } else if (doc.renaming) {
          return (doc.renaming = false);
        }
      });
    }

    function removeDocument(doc: any) {
      self.documents = self.documents.filter((d: any) => d.id != doc.id);
    }
    function getActivities(reset = false) {
      if (reset) {
        self.activities.page = 1;
        self.activities.data = [];
      }
      let additional_query: any = {};
      if (!self.is_root) {
        additional_query = { slug: self.parent_doc.slug };
      }
      if (
        (self.activities.page <= self.activities.pagination.total_page &&
          !self.activities.loading) ||
        (self.activities.page == 1 && !self.activities.loading)
      ) {
        self.activities.loading = true;
        appContext.config.globalProperties.app_service.getData(
          self.api_urls.activity,
          self.activities.page,
          (response: any, error: any) => {
            if (response) {
              self.activities.pagination = response.metadata;
              self.activities.data.push(...response.data);
              self.activities.page += 1;
            }
            if (error) {
              appContext.config.globalProperties.$toastMessage(
                appContext.config.globalProperties.$getErrorResponse(error)
              );
            }
            self.activities.loading = false;
          },
          true,
          additional_query
        );
      }
    }

    function viewDefaultContext(e: any) {
      if (docTable.value) {
        docTable.value.$refs.docTable.tableContext.hide();
      }
      if (docGrid.value) {
        docGrid.value.$refs.gridContext.hide();
      }
      if (defaultContext.value) {
        defaultContext.value.hide();
        setTimeout(() => {
          if (docTable.value || docGrid.value) {
            if (
              docTable.value.$refs.docTable.tableContext.visible ||
              docGrid.value.$refs.gridContext.visible
            )
              return;
          }
          if (self.is_root) {
            if (self.documents.length) {
              defaultContext.value.show(e);
            }
          } else {
            defaultContext.value.show(e);
          }
        }, 10);
      }
    }

    function updateMenu() {
      if (
        !self.is_root &&
        (self.properties.$appPermissions("media").edit == 1 ||
          self.properties.$appPermissions("media").manage == 1)
      ) {
        self.default_context_menu.push({
          label: "Paste",
          value: "paste",
          icon: "fa-solid fa-paste",
          command: () => {
            moveDocument(true);
          },
        });
      }
      if (
        self.selected_document.type != "project" &&
        (self.properties.$appPermissions("media").edit == 1 ||
          self.properties.$appPermissions("media").manage == 1)
      ) {
        self.menu_items.splice(4, 0, {
          label: "Paste",
          value: "paste",
          icon: "fa-solid fa-paste",
          command: () => {
            moveDocument();
          },
        });
      }
    }

    function getDefaultMenu() {
      if (
        !self.is_root &&
        (self.properties.$appPermissions("media").edit == 1 ||
          self.properties.$appPermissions("media").manage == 1)
      ) {
        const default_menu = [
          {
            label: "New Folder",
            value: "folder",
            icon: "fas fa-folder",
            command: () => {
              addNewFolder();
            },
            permission: "write",
          },
          {
            label: "New Folder From Template",
            value: "template_folder",
            icon: "fas fa-folder-open",
            command: () => {
              self.folder_template_modal = true;
            },
            permission: "write",
          },
          {
            label: "Upload Files",
            value: "upload",
            icon: "fas fa-upload",
            command: () => {
              docUploader.value.$refs.fileInput.click();
            },
            permission: "write",
          },
        ];
        self.default_context_menu = default_menu.filter(
          (context: any) =>
            self.properties.$appPermissions("media")[context.permission] == 1 ||
            self.properties.$appPermissions("media").manage == 1
        );
      }
    }

    function getMenuItems() {
      if (self.selectedDocuments.length > 1) {
        self.menu_items = [
          {
            label: "Download Selected Items",
            icon: "fas fa-download",
            command: () => {
              downloadSelectedDocuments();
            },
            permission: "read",
          },
        ];
      } else {
        const data = [
          {
            label: "Open",
            icon: "fas fa-folder-open",
            command: () => {
              getDocumentDetail({ data: self.selected_document });
            },
            permission: "read",
          },
          {
            label: "Download",
            icon: "fas fa-download",
            command: () => {
              downloadDocument();
            },
            permission: "read",
          },
          {
            label: "Rename",
            icon: "fa-solid fa-pen-to-square",
            command: () => {
              renameDocument();
            },
            permission: "edit",
          },
          {
            label: `${
              self.selected_document.starred ? "Remove From" : "Add To"
            } Starred`,
            icon: self.selected_document.starred
              ? "fa-regular fa-star"
              : "fa-solid fa-star",
            command: () => {
              self.selected_document.starred ? removeStarred() : addStarred();
            },
            permission: "edit",
          },
          {
            label: "Delete",
            icon: "fa-solid fa-trash",
            command: () => {
              deleteDocument();
            },
            permission: "delete",
          },
          {
            label: "View Analytics",
            icon: "fa-solid fa-chart-simple",
            command: () => {
              self.analytic_modal = true;
            },
            permission: "manage",
          },
          {
            label: "View Details",
            icon: "fa-solid fa-circle-info",
            command: () => {
              viewActivities(true, 0);
            },
            permission: "read",
          }
        ] as any;

        if (self.selected_document.can_change_ownership) {
          data.push({
            label: "Change Owner",
            icon: "fa-solid fa-people-arrows",
            command: () => {
              self.owner_modal = true;
            },
            permission: "edit",
          });
        }

        if (
          self.properties.$appPermissions("tags").edit == 1 ||
          self.properties.$appPermissions("tags").manage == 1
        ) {
          data.splice(4, 0, {
            label: "Tags",
            icon: "fa-solid fa-tag",
            command: () => {
              viewActivities(true, 3);
            },
            permission: "read",
          });
        }

        if (Object.keys(self.selected_document).length) {
          if (
            self.properties.$appPermissions("share").edit == 1 ||
            self.properties.$appPermissions("share").manage == 1 ||
            self.selected_document.permission.manage == 1
          ) {
            data.splice(
              self.properties.$appPermissions("tags").edit == 1 ||
                self.properties.$appPermissions("tags").manage == 1
                ? 5
                : 4,
              0,
              {
                label: "Share",
                icon: "fa-solid fa-share",
                command: () => {
                  self.share_modal = true;
                },
                permission: "manage",
              }
            );
          }
        } else {
          if (
            self.properties.$appPermissions("share").edit == 1 ||
            self.properties.$appPermissions("share").manage == 1
          ) {
            data.splice(
              self.properties.$appPermissions("tags").edit == 1 ||
                self.properties.$appPermissions("tags").manage == 1
                ? 5
                : 4,
              0,
              {
                label: "Share",
                icon: "fa-solid fa-share",
                command: () => {
                  self.share_modal = true;
                },
                permission: "manage",
              }
            );
          }
        }

        if (self.selected_document.type != "project") {
          data.splice(3, 0, {
            label: "Move To",
            icon: "fa-solid fa-up-down-left-right",
            command: () => {
              triggerFileMove();
            },
            permission: "edit",
          });
          if (
            self.moving_doc.type == "cut" && self.selected_document.type == "folder"
          ) {
            data.splice(4, 0, {
              label: "Paste",
              value: "paste",
              icon: "fa-solid fa-paste",
              command: () => {
                moveDocument();
              },
              permission: "edit",
            });
          } else {
            data.splice(4, 0, {
              label: "Cut",
              icon: "fa-solid fa-scissors",
              command: () => {
                self.moving_doc = {
                  detail: self.selected_document,
                  type: "cut",
                  view: false,
                };
              },
              permission: "edit",
            });
          }
          data.splice(6, 0, {
            label: `${self.selected_document.pinned ? "Unpin" : "Pin"}`,
            icon: "fa-solid fa-thumbtack",
            command: () => {
              self.selected_document.pinned ? unpinDocument() : pinDocument();
            },
            permission: "edit",
          });
        }

        if (Object.keys(self.selected_document).length) {
          if (self.selected_document.permission) {
            self.menu_items = data.filter(
              (context: any) =>
                // (self.properties.$appPermissions("media")[context.permission] ==
                //   1 ||
                //   self.properties.$appPermissions("media").manage == 1) ||
                (self.selected_document.permission[context.permission] == 1 ||
                  self.selected_document.permission.manage == 1)
            );
          }
        }
      }
    }

    function reloadAllData() {
      self.search_view = false;
      self.selectedDocuments = [];
      getDocument(true);
    }

    function addNewProject() {
      self.create = {
        id: "new",
        new: true,
        type: "project",
        name: "New Project",
        newName: "New Project",
      };
      self.documents.unshift(self.create);
      documentInputSelect(self.create);
    }

    function addNewFolder() {
      self.create = {
        id: "new",
        new: true,
        type: "folder",
        name: "New Folder",
        newName: "New Folder",
      };
      self.documents.unshift(self.create);
      documentInputSelect(self.create);
    }

    function createNew(type: any) {
      self.default_context_menu.forEach((menu: any) => {
        if (menu.value == type) {
          menu.command();
        }
      });
    }

    function createNewDocument(name: any) {
      const data = {
        name: name,
      };
      if (self.create.type == "folder") {
        Object.assign(data, {
          type: self.create.type,
          parent_slug: self.parent_doc.slug,
        });
      }
      appContext.config.globalProperties.app_service.createData(
        `${
          self.create.type == "project"
            ? self.api_urls.project
            : self.api_urls.media
        }`,
        data,
        (response: any, error: any) => {
          if (response) {
            appContext.config.globalProperties.$toastMessage(response);
            self.documents.unshift(response.data.data);
            self.documents = self.documents.filter((doc: any) => !doc.new);
            self.selectedDocuments = self.selectedDocuments.filter((doc: any) => !doc.new);
            self.create = {
              id: "new",
              new: false,
              type: "folder",
              name: "New Folder",
              newName: "New Folder",
            };
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
        }
      );
    }

    function getDocumentByParams(params: any) {
      getDocData(params.doc_type, params.doc_id);
      self.is_root = false;
    }

    function getDocument(reset = false) {
      if (reset) {
        self.page = 1;
        self.documents = [];
        viewActivities(true, self.change_activities ? 0 : 2);
        getActivities(true);
      }
      if (!self.is_root) {
        return getItemChildren(reset);
      }
      if (self.search_view) {
        return searchDocuments(self.search_values, reset);
      }

      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading_docs = true;
        self.loading = true;
        appContext.config.globalProperties.app_service.getData(
          self.api_urls.my_vault,
          self.page,
          (response: any, error: any) => {
            self.search_view = false;
            processListing(response, error);
          },
          true,
          self.sort_by_query ? { sorting: self.current_sort } : null
        );
      }
    }

    async function getFilterSuggestions(query: string, reset = false) {
      if (reset) {
        self.suggestions.page = 1;
        self.suggestions.data = [];
      }
      if (
        (self.suggestions.page <= self.suggestions.pagination.total_page &&
          !self.suggestions.loading) ||
        (self.suggestions.page == 1 && !self.suggestions.loading)
      ) {
        self.suggestions.loading = true;
        const search_data = {
          query: query,
          owner: self.search_values.owner ? self.search_values.owner : "all",
        };
        if (!self.is_root) {
          Object.assign(search_data, { parent_slug: self.parent_doc.slug });
        }
        await appContext.config.globalProperties.app_service.searchData(
          null,
          self.suggestions.page,
          search_data,
          (response: any, error: any) => {
            if (response) {
              if (response.metadata) {
                self.pagination = response.metadata;
              }
              self.suggestions.data.push(...response.data);
              self.suggestions.page += 1;
            }
            if (error) {
              appContext.config.globalProperties.$toastMessage(
                appContext.config.globalProperties.$getErrorResponse(error)
              );
            }
            self.suggestions.loading = false;
          }
        );
      }
    }

    function searchDocuments(filter_value: any, reset = true) {
      if (reset) {
        self.page = 1;
        self.documents = [];
        // self.loading_docs = true;
      }

      self.search_values = filter_value;
      self.search_view = true;
      if (!self.is_root) {
        Object.assign(self.search_values, {
          parent_slug: self.parent_doc.slug,
        });
      }
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading_docs = true;
        self.loading = true;
        appContext.config.globalProperties.app_service.searchData(
          null,
          self.page,
          self.search_values,
          (response: any, error: any) => {
            processListing(response, error);
          },
          false,
          self.sort_by_query ? { sorting: self.current_sort } : null
        );
      }
    }

    function getItemChildren(reset = false) {
      if (reset) {
        self.page = 1;
        self.sort_by_query = false;
        self.documents = [];
        setDocPath();
        viewActivities(true, self.change_activities ? 0 : 2);
        getActivities(true);
        // self.loading_docs = true;
      }
      self.is_root = false;
      if (self.parent_doc.id) {
        if (
          (self.page <= self.pagination.total_page && !self.loading) || (self.page == 1 && !self.loading)
        ) {
          self.loading_docs = true;
          self.loading = true;
          appContext.config.globalProperties.app_service.getData(
            `${self.parent_doc.type == "project" ? self.api_urls.project : self.api_urls.media }/${self.parent_doc.id}/childs`,
            self.page,
            (response: any, error: any) => {
              processListing(response, error);
              setTimeout(() => {
                if (reset) {
                  setDocBreadCrumb();
                }
              }, 500);
            },
            true,
            self.sort_by_query ? { sorting: self.current_sort } : null
          );
        }
      }
    }

    function getDocData(type: any, id: any) {
      const api_url =
        type == "project" || type == "projects"
          ? self.api_urls.project
          : self.api_urls.media;
      appContext.config.globalProperties.app_service.getData(
        `${api_url}/${id}`,
        null,
        (response: any, error: any) => {
          if (response) {
            self.selected_document = response;
            self.parent_doc = self.selected_document;
            setDocPath();
            getItemChildren(true);
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
        },
        false
      );
    }

    function setDocBreadCrumb() {
      if (self.pagination.breadcrumbs.length) {
        const breadcrumbs = self.pagination.breadcrumbs.map(
          (breadcrumb: any) => {
            return {
              label: breadcrumb.name,
              to: {
                name:
                  self.properties.$route.name == "projectInfo"
                    ? "projectInfo"
                    : "myVaultDetail",
                params: getParams(breadcrumb),
              },
              ...breadcrumb,
            };
          }
        );
        if (self.search_view) {
          breadcrumbs.push({
            label: "Search Results",
            icon: "pi pi-search",
          });
        }
        self.breadcrumbItems = breadcrumbs;
      }
    }

    function setDocPath() {
      if (self.properties.$route.name == "projectInfo") {
        self.breadcrumbHome = null;
      }
      appContext.config.globalProperties.$router.push({
        name:
          self.properties.$route.name == "projectInfo"
            ? "projectInfo"
            : "myVaultDetail",
        params: getParams(self.parent_doc),
      });
    }

    function getParams(doc: any) {
      const params = {
        doc_type: doc.type,
        doc_id: doc.id,
      };
      if (self.properties.$route.name == "projectInfo") {
        Object.assign(params, {
          id: self.properties.$route.params.id,
          tab: self.properties.$route.params.tab,
        });
      }
      return params;
    }

    function processListing(res: any, err: any) {
      if (res) {
        self.pagination = res.metadata;
        self.documents.push(...res.data);
        self.page += 1;
      }
      if (err) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(err)
        );
      }
      self.loading = false;
      self.loading_docs = false;
      self.change_activities = true;
    }

    function previewImage() {
      self.preview_img = true;
      updateAnalytics();
    }

    function updateAnalytics() {
      self.user_session = uuidv4();
      self.properties.app_service.createData(
        self.api_urls.analytics,
        {
          user_session: self.user_session,
          document_id: self.selected_document.id,
          current_timestamp: Date.now(),
        },
        () => {
          return;
        }
      );
    }

    function previewDocument(page = 1) {
      if (self.selected_document.text_extraction_pending)
        return appContext.config.globalProperties.$toastMessage({
          status: "info",
          data: "Text Extraction Pending Please Wait",
        });
      if (
        appContext.config.globalProperties.$getContentType(
          self.selected_document
        ) != "pdf"
      ) {
        if (!self.selected_document.proxy_path)
          return appContext.config.globalProperties.$toastMessage({
            status: "warn",
            data: "File is not supported for the preview",
          });
      }

      self.pdf_page = {
        view: true,
        page: page,
      };
      self.preview_pdf = true;
    }

    function getDocumentDetail(e: any) {
      self.selected_document = e.data;
      if (
        appContext.config.globalProperties.$getContentType(e.data) ==
          "project" ||
        appContext.config.globalProperties.$getContentType(e.data) == "folder"
      ) {
        self.parent_doc = self.selected_document;
        self.sort_by_query = false;
        getItemChildren(true);
      } else if (
        appContext.config.globalProperties.$getContentType(e.data) == "image"
      ) {
        previewImage();
      } else {
        previewDocument();
      }
      self.selectedDocuments = [];
    }

    function renameDocument(val = true, newName: any = null) {
      self.documents.forEach((doc: any) => {
        if (doc.id == self.selected_document.id) {
          if (val) {
            doc.newName = doc.name;
          }
          if (newName != null) {
            doc.name = newName;
          }
          doc.renaming = val;
          documentInputSelect(doc);
        }
      });
      self.drag_selector = false;
    }

    function documentInputSelect(doc: any) {
      const docRef = self.current_view == "grid" ? docGrid : docTable;
      removeClickEvent();
      setTimeout(() => {
        self.drag_selector = false;
        const inputData = docRef.value
          ? docRef.value.$refs[`docName${doc.id}`]
            ? docRef.value.$refs[`docName${doc.id}`][0]
              ? docRef.value.$refs[`docName${doc.id}`][0]
              : docRef.value.$refs[`docName${doc.id}`]
            : null
          : null;
        if (inputData) {
          if (inputData.focus) {
            inputData.focus();
            inputData.select();
            self.properties.$scrollToDiv("#new");
          }
        }
        addClickEvent();
      }, 200);
    }

    function viewActivities(val: boolean, tab = 0) {
      self.activities.view = val;
      self.activities.active_tab = tab;
    }

    function viewActivityDetail(item: any) {
      if (item.action == "shared") {
        return self.properties.$router.push({
          name: "sharedByMe",
          query: {
            ref: `${item.reference.reference_object}-${item.reference.reference_id}`,
          },
        });
      }
      if (
        item.reference.reference_object == "media" ||
        item.reference.reference_object == "projects" ||
        item.reference.reference_object == "project"
      ) {
        appContext.config.globalProperties.app_service.getData(
          `${
            item.reference.reference_object == "media" ? "media" : "projects"
          }/${item.reference.reference_id}`,
          null,
          (response: any) => {
            if (response) {
              if (response.status != "trash" || response.status != "deleted") {
                getDocumentDetail({ data: response });
              }
            }
          }
        );
      }
    }

    function dragedOverDocument(e: any, doc: any) {
      e.preventDefault();
      if (doc) {
        if (doc.type != "file") {
          self.dragged_selected_document = doc;
          self.view_dropzone = false;
        }
      }
    }
    function dragedLeaveDocument() {
      self.dragged_selected_document = {} as any;
    }

    function draggedDocument(e: any, doc: any) {
      self.dragged_doc = doc;
      self.drag_selector = false;
    }
    function stopDrag() {
      self.dragged_doc = {} as any;
      self.dragged_selected_document = {} as any;
      self.drag_selector = true;
    }

    function uploadOnDocument(e: any, doc: any) {
      if (doc) {
        self.dragged_selected_document = doc;
      }
      if (Object.keys(self.dragged_doc).length) {
        moveSelectedDocument();
      } else {
        uploadDoc(e);
      }
      self.drag_selector = true;
    }

    function uploadDoc(e: any) {
      if (self.is_root && !Object.keys(self.dragged_selected_document).length) {
        self.view_dropzone = false;
        return appContext.config.globalProperties.$toastMessage({
          status: 400,
          data: {
            message: "Upload is not allowed here, please right click and create a project folder to get started.",
          },
        });
      }
      docUploader.value.uploadDocument(e);
      setTimeout(() => {
        self.dragged_selected_document = {} as any;
      }, 1000);
    }
    function updateDocName(name: string) {
      if (name.length) {
        updateDocument(name);
      } else {
        cancelCreateNew();
      }
    }

    async function updateDocument(name: any, api_load = true) {
      const data = {
        name: name,
      };
      if (api_load) {
        await appContext.config.globalProperties.app_service.updateData(
          `${
            self.selected_document.type == "project"
              ? self.api_urls.project
              : self.api_urls.media
          }/${self.selected_document.id}`,
          data,
          null,
          (response: any, error: any) => {
            if (response) {
              appContext.config.globalProperties.$toastMessage(response);
              renameDocument(false, name);
            }
            if (error) {
              appContext.config.globalProperties.$toastMessage(
                appContext.config.globalProperties.$getErrorResponse(error)
              );
            }
          }
        );
      } else {
        renameDocument(false);
      }
    }

    function downloadDocument() {
      const download_data = {
        projects:
          self.selected_document.type == "project"
            ? [self.selected_document.id]
            : null,
        media:
          self.selected_document.type != "project"
            ? [self.selected_document.id]
            : null,
      };
      self.processing_download = true;
      appContext.config.globalProperties.app_service.createData(
        "downloads",
        download_data,
        (response: any, error: any) => {
          if (response) {
            if (response.data.data.zip_download) {
              self.download = {
                modal: true,
                message: response.data.message,
              };
            } else {
              appContext.config.globalProperties.$downloadItem(
                response.data.data.url,
                false
              );
            }
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
          self.processing_download = false;
        }
      );
    }

    function downloadSelectedDocuments() {
      const download_list = {
        projects: [] as any,
        media: [] as any,
      };
      self.selectedDocuments.forEach((doc: any) => {
        if (doc.type == "project" || doc.type == "projects") {
          download_list.projects.push(doc.id);
        } else {
          download_list.media.push(doc.id);
        }
      });
      self.processing_download = true;
      appContext.config.globalProperties.app_service.createData(
        "downloads",
        download_list,
        (response: any, error: any) => {
          if (response) {
            if (response.data.data.zip_download) {
              self.download = {
                modal: true,
                message: response.data.message,
              };
            }
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
          self.processing_download = false;
        }
      );
    }

    function deleteDocument() {
      self.properties.$confirm.require({
        group: "confirmDialog",
        message: "Are you sure you want to trash document ?",
        header: "Trash Document",
        position: "top",
        rejectLabel: "No",
        acceptLabel: "Yes",
        accept: () => {
          self.properties.app_service.deleteData(
            `${
              self.selected_document.type == "project"
                ? self.api_urls.project
                : self.api_urls.media
            }`,
            self.selected_document,
            (response: any, error: any) => {
              if (response) {
                self.properties.$toastMessage(response);
                self.documents = self.documents.filter(
                  (doc: any) => doc.id != self.selected_document.id
                );
              }
              if (error) {
                appContext.config.globalProperties.$toastMessage(
                  appContext.config.globalProperties.$getErrorResponse(error)
                );
              }
            },
            { permanent: false }
          );
        },
      });
    }

    function removeStarred() {
      appContext.config.globalProperties.app_service.deleteData(
        `${
          self.selected_document.type == "project"
            ? self.api_urls.project
            : self.api_urls.media
        }`,
        { id: `${self.selected_document.id}/starred` },
        (response: any, error: any) => {
          if (response) {
            appContext.config.globalProperties.$toastMessage(response);
            updateStarred();
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
        }
      );
    }
    function addStarred() {
      appContext.config.globalProperties.app_service.createData(
        `${
          self.selected_document.type == "project"
            ? self.api_urls.project
            : self.api_urls.media
        }/${self.selected_document.id}/starred`,
        {},
        (response: any, error: any) => {
          if (response) {
            appContext.config.globalProperties.$toastMessage(response);
            updateStarred();
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
        }
      );
    }

    function pinDocument() {
      appContext.config.globalProperties.app_service.createData(
        `${
          self.selected_document.type == "project"
            ? self.api_urls.project
            : self.api_urls.media
        }/${self.selected_document.id}/pin`,
        {},
        (response: any, error: any) => {
          if (response) {
            appContext.config.globalProperties.$toastMessage(response);
            updatePinned();
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
        }
      );
    }

    function unpinDocument() {
      appContext.config.globalProperties.app_service.deleteData(
        `${
          self.selected_document.type == "project"
            ? self.api_urls.project
            : self.api_urls.media
        }`,
        { id: `${self.selected_document.id}/pin` },
        (response: any, error: any) => {
          if (response) {
            appContext.config.globalProperties.$toastMessage(response);
            updatePinned();
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
        }
      );
    }

    function revokedShared(revoked_all = false) {
      if (revoked_all) {
        self.documents.forEach((doc: any) => {
          if (doc.id == self.selected_document.id) {
            doc.shared = false;
          }
        });
      }
    }

    function updateShared() {
      self.share_modal = false;
      self.documents.forEach((doc: any) => {
        if (doc.id == self.selected_document.id) {
          doc.shared = true;
        }
      });
    }

    function updatePinned() {
      self.documents.forEach((doc: any) => {
        if (doc.id == self.selected_document.id) {
          doc.pinned = !doc.pinned;
        }
      });
    }

    function updateStarred() {
      self.documents.forEach((doc: any) => {
        if (doc.id == self.selected_document.id) {
          doc.starred = !doc.starred;
        }
      });
    }

    function triggerFileMove() {
      self.moving_doc = {
        detail: self.selected_document,
        type: "move",
        view: true,
      };
      self.move_data.loading = true;
      self.move_data.data = self.documents.filter(
        (doc: any) => doc.type != "file"
      );
      self.move_data.loading = false;
      self.move_data.breadcrumbs = self.breadcrumbItems.map(
        (breadcrumb: any) => {
          return {
            label: breadcrumb.name,
            command: () => {
              getMoveListingData(breadcrumb, true, false, true);
            },
            ...breadcrumb,
          };
        }
      );
    }

    function getMoveListingData(
      val: any,
      reset = false,
      go_to_parent = false,
      reset_selection = false
    ) {
      self.move_data.selected_doc = reset_selection ? {} : val;
      if (reset) {
        self.move_data.page = 1;
        self.move_data.data = [];
      }
      if (
        (self.move_data.page <= self.move_data.pagination.total_page &&
          !self.move_data.loading) ||
        (self.move_data.page == 1 && !self.move_data.loading)
      ) {
        self.move_data.loading = true;
        appContext.config.globalProperties.app_service.getData(
          go_to_parent
            ? self.api_urls.my_vault
            : `${
                val.type == "project"
                  ? self.api_urls.project
                  : self.api_urls.media
              }/${val.id}/childs`,
          self.move_data.page,
          (response: any, error: any) => {
            if (response) {
              self.move_data.pagination = response.metadata;
              self.move_data.data.push(...response.data);
              self.move_data.data = self.move_data.data.filter(
                (data: any) => data.type != "file"
              );
              self.move_data.page += 1;
              self.move_data.breadcrumbs = response.metadata.breadcrumbs
                ? response.metadata.breadcrumbs.map((breadcrumb: any) => {
                    return {
                      label: breadcrumb.name,
                      command: () => {
                        getMoveListingData(breadcrumb, true, false, true);
                      },
                      ...breadcrumb,
                    };
                  })
                : [];
            }
            if (error) {
              appContext.config.globalProperties.$toastMessage(
                appContext.config.globalProperties.$getErrorResponse(error)
              );
            }
            self.move_data.loading = false;
          }
        );
      }
    }

    function moveSelectedDocument() {
      if (
        self.dragged_selected_document.type == "file" ||
        self.dragged_doc.id == self.dragged_selected_document.id
      ) {
        self.view_dropzone = false;
        return appContext.config.globalProperties.$toastMessage({
          status: 400,
          data: {
            message:
              self.dragged_doc.id == self.dragged_selected_document.id
                ? "Cannot Move Inside Same Document Choose a different Folder or a Project"
                : "Cannot Move Inside a file choose a folder or a project",
          },
        });
      }

      appContext.config.globalProperties.app_service.updateData(
        `${self.api_urls.media}/${self.dragged_doc.id}/_move`,
        {
          parent_slug: self.dragged_selected_document.slug,
        },
        null,
        (response: any, error: any) => {
          if (response) {
            appContext.config.globalProperties.$toastMessage(response);
            reloadAllData();
            self.dragged_doc = {} as any;
            self.dragged_selected_document = {} as any;
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
        },
        null,
        true
      );
    }
    function moveDocument(main_menu = false) {
      const data = {
        parent_slug:
          self.moving_doc.type == "cut"
            ? self.selected_document.type != "folder"
              ? self.parent_doc.slug
              : main_menu
              ? self.parent_doc.slug
              : self.selected_document.slug
            : self.move_data.selected_doc.slug,
      };
      appContext.config.globalProperties.app_service.updateData(
        `${self.api_urls.media}/${self.moving_doc.detail.id}/_move`,
        data,
        null,
        (response: any, error: any) => {
          if (response) {
            appContext.config.globalProperties.$toastMessage(response);
            reloadAllData();
            self.moving_doc = {
              detail: {} as any,
              type: "",
              view: false,
            };
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
        },
        null,
        true
      );
    }

    function onUploadComplete(completed: any, all_completed: any) {
      if (completed) {
        viewActivities(true, 1);
        setTimeout(() => {
          viewActivities(true, 2);
        }, 100);
      }
      if (all_completed) {
        reloadAllData();
        self.activities.view = false;
        self.canceled_upload = 0;
        self.dragged_selected_document = {} as any;
        self.change_activities = false;
      }
    }

    function openSocketConnection() {
      self.properties.app_service.getSocketConnection(
        self.properties,
        "documents",
        (response: any) => {
          if (response.data) {
            updateDocumentListing(response.data);
            setTimeout(() => {
              if (response.reload) {
                return openSocketConnection();
              }
            }, 300);
          }
        }
      );
    }

    function updateDocumentListing(data: any) {
      if (data.reference.reference_id == self.parent_doc.id) {
        const documentIndex: any = self.documents.findIndex(
          (doc: any) => doc.id == data.id
        );
        if (data.user_event == "added") {
          self.documents.unshift(data);
        }
        if (data.user_event == "trashed" || data.user_event == "deleted") {
          self.documents = self.documents.filter(
            (doc: any) => doc.id != data.id
          );
        }
        if (data.user_event == "updated") {
          self.documents[documentIndex] = data;
        }
        if (data.user_event == "renamed") {
          self.documents[documentIndex].name = data.name;
        }
      }
      self.update_listing = true;
    }

    function togglemoveToBreadcrumbItem(e: any) {
      moveTobreadcrumbItem.value.toggle(e);
    }

    function addClickEvent() {
      document.addEventListener("click", onClickOutside);
      document.addEventListener("contextmenu", onClickOutside, false);
    }

    function removeClickEvent() {
      document.removeEventListener("click", onClickOutside);
      document.removeEventListener("contextmenu", onClickOutside, false);
    }

    function onClickOutside(e: any) {
      const docRef = self.current_view == "grid" ? docGrid : docTable;
      if (docRef.value) {
        const refs = Object.keys(docRef.value.$refs);
        const target = e.target;
        if (e.type == "contextmenu") {
          if (self.create.new) {
            cancelCreateNew();
          }
          self.documents.forEach((doc: any) => {
            doc.new = false;
            doc.renaming = false;
          });
        }
        setTimeout(() => {
          self.documents.forEach((doc: any) => {
            if (refs.includes(`docName${doc.id}`)) {
              if (
                docRef.value.$refs[`docName${doc.id}`] != null ||
                docRef.value.$refs[`docName${doc.id}`] != undefined
              ) {
                if (self.current_view == "grid") {
                  if (target != docRef.value.$refs[`docName${doc.id}`][0]) {
                    processClickEvent(doc);
                  }
                } else {
                  if (target != docRef.value.$refs[`docName${doc.id}`]) {
                    processClickEvent(doc);
                  }
                }
              }
            }
          });
        }, 500);
      }
    }

    function processClickEvent(doc: any) {
      if (doc.renaming) {
        updateDocument(doc.newName);
      } else {
        if (self.create.new) {
          createNewDocument(self.create.newName);
        }
      }
    }

    return {
      docTable,
      docGrid,
      docUploader,
      defaultContext,
      appData,
      appContext,
      self,
      moveTobreadcrumbItem,
      viewDefaultContext,
      triggerKeyPress,
      getActivities,
      reloadAllData,
      addNewFolder,
      addNewProject,
      getDocument,
      searchDocuments,
      getFilterSuggestions,
      processListing,
      getMenuItems,
      getItemChildren,
      getDocumentDetail,
      previewImage,
      downloadDocument,
      viewActivities,
      viewActivityDetail,
      createNew,
      uploadOnDocument,
      dragedOverDocument,
      dragedLeaveDocument,
      draggedDocument,
      stopDrag,
      uploadDoc,
      updateAnalytics,
      updateDocument,
      downloadSelectedDocuments,
      deleteDocument,
      removeStarred,
      addStarred,
      unpinDocument,
      pinDocument,
      updateShared,
      triggerFileMove,
      getMoveListingData,
      moveDocument,
      createNewDocument,
      updateMenu,
      onUploadComplete,
      previewDocument,
      updateDocName,
      openSocketConnection,
      revokedShared,
      togglemoveToBreadcrumbItem,
      addClickEvent,
      removeClickEvent,
      onClickOutside,
      processClickEvent,
    };
  },
});
</script>