import appLayout from "@/configs/app";
const routes = [
  {
    path: "/",
    name: "home",
    component: appLayout.layout,
    redirect: { name: "projectIndex" },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: appLayout.dashboard.index,
        meta: {
          title: "Dashboard",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "dashboard",
          },
        },
      },
      {
        path: "queue",
        name: "queueMonitior",
        component: appLayout.dashboard.queue_monitor,
        meta: {
          title: "Queue Monitor",
          requiresAuth: true,
          faq: {
            view: false,
          },
        },
      },
      {
        path: "active-users",
        name: "activeUsers",
        component: appLayout.dashboard.active_user,
        meta: {
          title: "Active Users",
          requiresAuth: true,
          faq: {
            view: false,
          },
        },
      },
    ],
  },
];
export default routes;