<template>
  <div class="widget-data">
    <div v-if="!widgets.length" class="dropzone flex align-content-center justify-content-center flex-wrap">
      <div class="text-700 text-center">
        <h3 class="mb-1">
          <b>Drop widget here from the widget list</b>
        </h3>
        <p class="text-muted m-0">
          Click the widget button to see the available widgets
        </p>
      </div>
    </div>
    <draggable :list="widgets" :disabled="false" item-key="name" class="grid-group flex flex-wrap" group="widget"
      @start="dragging = true" @end="dragging = false" @change="$emit('update-list', { type: 'dragged', event: $event })">
      <template #item="data">
        <Card style="width: 32.32%; height: 190px" class="widget-box mr-2" :class="`${data.element.slug}`">
          <template #header>
            <div class="p-2 pt-4 flex justify-content-between flex-wrap">
              <!-- <Tag
                :severity="widget_data[data.element.slug].color"
                :value="data.element.title"
                rounded
              /> -->
              <Tag :severity="widget_data.project.color" :value="data.element.title" rounded />
              <Button @click="$emit('update:updateWidgets', data.element)" icon="icofont icofont-close"
                severity="secondary" rounded class="close-button" aria-label="Close" />
            </div>
          </template>
          <template #title>
            <div class="flex justify-content-between flex-wrap">
              <h2 v-html="getWidgetCount(data.element)"></h2>
              <Button :icon="widget_data[data.element.slug].icon" :severity="widget_data.project.color" rounded
                aria-label="Link" />
              <!-- <Button
                :icon="widget_data[data.element.slug].icon"
                :severity="widget_data[data.element.slug].color"
                rounded
                aria-label="Link"
              /> -->
            </div>
          </template>
          <template #content>
            <Button class="mt-2 content-button" icon="fas fa-chart-line" text rounded
              :label="getWidgetContent(data.element)" />
          </template>
        </Card>
      </template>
    </draggable>
  </div>
</template>
<script setup lang="ts">
import { getCurrentInstance, defineAsyncComponent, ref, reactive } from "vue";
import type { Ref } from "vue";
const props: any = defineProps(["widgets", "summary"]);
defineEmits(["update:updateWidgets", "update-list"]);
const draggable = defineAsyncComponent(() => import("vuedraggable"));
const { appContext }: any = getCurrentInstance();
const dragging: Ref<boolean> = ref(false);
const widget_data = reactive({
  folder: {
    color: "info",
    icon: "fa fa-folder-open",
  },
  document: {
    color: "warning",
    icon: "fa fa-file",
  },
  project: {
    color: process.env.VUE_APP_THEME == 'red' ? 'danger' : "primary",
    icon: "fa-solid fa-layer-group",
  },
}) as any;

function getWidgetCount(el: any) {
  return el.slug == "document"
    ? `${props.summary.file_count
    } <small>(${appContext.config.globalProperties.$getFileSize(
      props.summary.file_size
    )})</small>`
    : el.slug == "folder"
      ? props.summary.folder_count
      : el.slug == "project"
        ? props.summary.project_count
        : props.summary.file_count;
}
function getWidgetContent(el: any) {
  return `${el.slug == "document"
      ? props.summary.today.file_count
      : el.slug == "folder"
        ? props.summary.today.folder_count
        : el.slug == "project"
          ? props.summary.today.project_count
          : props.summary.today.file_count
    } ${el.slug}(s) today ${el.slug == "document"
      ? `(${appContext.config.globalProperties.$getFileSize(
        props.summary.today.file_size
      )})`
      : ""
    }`;
} 
</script>