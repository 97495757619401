import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "the-content" }
const _hoisted_2 = {
  key: 0,
  class: "message"
}
const _hoisted_3 = { class: "error" }
const _hoisted_4 = { class: "flex align-items-center justify-content-end mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      permission: "user",
      breadcrumb_home: _ctx.self.breadcrumbItems,
      options: _ctx.self.toolbarOptions,
      loading: _ctx.self.loading
    }, null, 8, ["breadcrumb_home", "options", "loading"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" Remember csv file can be of any number of column and rows but HEADER must have one column name labeled as Email or email. ")
        ])),
        content: _withCtx(() => [
          _createVNode(_component_Toolbar, null, {
            start: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Select a file ")
            ])),
            end: _withCtx(() => [
              _createVNode(_component_FileUpload, {
                ref: "fileUpload",
                mode: "basic",
                name: "demo[]",
                onSelect: _ctx.onFileSelect,
                onRemove: _ctx.onFileRemove,
                onClear: _ctx.onFileRemove,
                accept: "text/csv"
              }, null, 8, ["onSelect", "onRemove", "onClear"])
            ]),
            _: 1
          }),
          (_ctx.self.partial_success)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                _cache[3] || (_cache[3] = _createElementVNode("h4", null, "Some email address are not found in the database.", -1)),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.self.email_not_found, (item, index) => {
                      return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(item), 1))
                    }), 128))
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Button, {
              type: "submit",
              label: "Upload File",
              loading: _ctx.self.disabling_users,
              onClick: _withModifiers(_ctx.uploadDisableUsers, ["prevent"]),
              class: "button-primary"
            }, null, 8, ["loading", "onClick"])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}