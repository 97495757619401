import AxiosHttpClient from "@/library/system/httpclients/axios";
export default class SystemService extends AxiosHttpClient {
  static getHttpClient(): any {
    throw new Error("Method not implemented.");
  }
  
  constructor() {
    super();
  }

  updateUserPreference(properties: any, key: string, value: any, cb: any) {
    this.patch(
      "profile/user-preference",
      {
        update_data: [
          {
            path: `/${key}`,
            op: "replace",
            value: value,
          },
        ],
      },
      {}
    )
      .then((response: any) => {
        cb(response);
        properties.$store.commit("system/updateUserPreference", { [key]: value});
      })
      .catch((error: any) => {
        cb(null, error);
      });
  }

  getData(
    url: string,
    page = 1,
    cb: any,
    load_query = true,
    additional_query: any = null
  ) {
    let query = {};
    if (load_query) {
      query = {
        page: page,
      };
    }
    if (additional_query) {
      Object.assign(query, additional_query);
    }
    this.get(url, query, {})
      .then((response: any) => {
        cb(response.data);
      })
      .catch((error: any) => {
        cb(null, error);
      });
  }

  searchData(
    url: string,
    page = 1,
    search_values: any,
    cb: any,
    only_url = false,
    additional_query: any = null
  ) {
    const query = {
      page: page,
    };
    if (additional_query) {
      Object.assign(query, additional_query);
    }
    const filtered_search_values: any = Object.keys(search_values).map(
      (key) => {
        if (search_values[key]) {
          return {
            [key]: search_values[key],
          };
        }
      }
    );
    const new_url = only_url ? url : url ? `${url}/_search` : "_search";
    const new_val: any = Object.assign({}, ...filtered_search_values);
    this.post(new_url, new_val, query, {})
      .then((response: any) => {
        cb(response.data);
      })
      .catch((error: any) => {
        cb(null, error);
      });
  }

  updateData(
    url: string,
    data: any,
    data_id: any = null,
    cb: any,
    patch_type: any = null,
    default_data = false
  ) {
    this.patch(
      patch_type
        ? `${url}/${data_id}/${patch_type}`
        : data_id
        ? `${url}/${data_id}`
        : url,
      default_data ? data : this.updateValueData(data),
      {}
    )
      .then((response: any) => {
        cb(response);
      })
      .catch((error: any) => {
        cb(null, error);
      });
  }

  deleteData(url: string, data: any, cb: any, query: any = null) {
    this.delete(data ? `${url}/${data.id}` : url, query, {})
      .then((response: any) => {
        cb(response);
      })
      .catch((error: any) => {
        cb(null, error);
      });
  }

  createData(url: string, data: object, cb: any, query = {}, config_data = {}) {
    this.post(url, data, query, config_data)
      .then((response: any) => {
        cb(response);
      })
      .catch((error: any) => {
        cb(null, error);
      });
  }

  fileUploadData(url: string, data: object, cb: any, query = {}, config_data = {}) {
    this.post(url, data, query, config_data)
      .then((response: any) => {
        cb(response);
      })
      .catch((error: any) => {
        cb(null, error);
      });
  }

  getSocketConnection(properties: any, type: string, cb: any) {
    setTimeout(() => {
      const token: any = `Bearer ${properties.$store.getters["setting/getUserToken"]}`;
      const ws = new WebSocket(`${process.env.VUE_APP_SOCKET_URL}${type}`);
      let data: any = { data: null, reload: false };
      ws.onopen = () => {
        ws.send(token);
      };
      ws.onerror = () => {
        ws.close();
        data = { data: null, reload: true };
        cb(data);
      };
      ws.onmessage = (evt) => {
        data = { data: JSON.parse(evt.data), reload: false };
        cb(data);
      };
      ws.onclose = () => {
        ws.close();
        data = { data: null, reload: true };
        cb(data);
      };
    }, 1000);
  }

  getObjectValue(detail: any, child_values = [] as any) {
    const main_object = {} as any;
    Object.keys(detail).forEach((key: any) => {
      main_object[key] = child_values.includes(key)
        ? detail[key].value
          ? this.getValues(detail[key])
          : []
        : detail[key]
        ? detail[key].value
          ? detail[key].value
          : null
        : null;
    });
    return main_object;
  }

  getValues(data: any) {
    return {
      tags_name: data.value.map((tag: any) => {
        return tag.name;
      }),
      tags_value: data.value.map((tag: any) => {
        return tag.value;
      }),
    };
  }

  updateValueData(detail: any, exclude_items: any = []) {
    const main_object = {
      update_data: [] as any,
    };
    Object.keys(detail).forEach((key: any) => {
      if (!exclude_items.includes(key)) {
        main_object.update_data.push({
          path: `/${key}`,
          op: "replace",
          value: detail[key]
            ? detail[key]
            : typeof detail[key] == "boolean"
            ? detail[key]
            : null,
        });
      }
    });
    return main_object;
  }

  parseJSON(value: any) {
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  }
}