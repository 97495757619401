<template>
  <div>
    <div id="sub-header">
      <Toolbar id="breadcrumb" class="py-1 main-toolbar" :media_manage="media_manage" :project_manage="project_manage">
        <template #start>
          <Breadcrumb :home="breadcrumbItems">
            <template #item="{ label, item, props }">
              <router-link
                v-if="item.to"
                :to="item.to"
              >
                <span v-bind="props.action">
                  <span v-bind="props.icon" />
                  <span v-bind="props.label">{{ label }}</span>
                </span>
              </router-link>
              <a
                v-else
                :href="item.url"
                :target="item.target"
                v-bind="props.action"
              >
                <span v-if="item.icon" v-bind="props.icon" />
                <span v-bind="props.label">{{ label }}</span>
              </a>
            </template>
          </Breadcrumb>
        </template>
        <template #end>
          <span class="mx-2" v-if="media_manage && project_manage">
            <Dropdown
                inputId="user"
                v-model="widgetData"
                :options="widgetDataFilter"
                optionLabel="name"
                optionValue="slug"
                @change="getSummary"
              />
          </span>
          <Button
            @click="toggleWidgetSidebar"
            icon="fas fa-th-large mr-1"
            aria-label="Widget"
            label="Widget"
            class="widget-btn"
          />
        </template>
      </Toolbar>
      <AppProgressBar :loading="!view_widgets" />
    </div>
    <div class="the-content">
      <div class="grid m-0">
        <div
          class="col width-transaction"
          :class="[view_widget_sidebar ? 'col-9' : 'col-12']"
        >
          <WidgetsDisplay
            v-if="view_widgets"
            :widgets="dragged_widgets"
            :summary="summary"
            @update:updateWidgets="removeDragged"
            @update-list="updatePreference"
          />
          <div v-if="!view_widgets" class="text-center my-4">
            <ProgressSpinner
              style="width: 20px; height: 20px"
              strokeWidth="5"
              animationDuration=".5s"
              aria-label="Loading"
            />
          </div>
        </div>
        <Sidebar
          v-model:visible="view_widget_sidebar"
          position="right"
          :dismissable="false"
          :modal="false"
        >
          <template #header> Widgets </template>
          <WidgetsLists
            ref="widgetListData"
            :widgets="un_dragged_widgets"
            :sidebar_refresh="sidebar_refresh"
            v-model:viewSidebar="view_widget_sidebar"
            @update-list="updatePreference"
          />
        </Sidebar>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  getCurrentInstance,
  defineAsyncComponent,
  ref,
  reactive,
  onMounted,
} from "vue"; 
import type { Ref } from "vue";
const { appContext }:any = getCurrentInstance();
const AppProgressBar = defineAsyncComponent(
  () => import("@/components/common/progress_bar/loading.indicator.vue")
);
const WidgetsDisplay = defineAsyncComponent(
  () => import("./dashboard/widgetDisplay.vue")
);
const WidgetsLists = defineAsyncComponent(
  () => import("./dashboard/widgetList.vue")
);
const breadcrumbItems = reactive({
  icon: "fas fa-home mr-1",
  label: "Dashboard",
  to: { name: "dashboard" },
}); 

const widgetData = ref("me");
const widgetDataFilter = ref([
    { name: "Owned by me", slug: "me" },
    { name: "Any One", slug: "all" },
]);

const globalProperties = appContext.config.globalProperties;
const store = globalProperties.$store;
const media_manage = globalProperties.$appPermissions("media").manage || 0;
const project_manage = globalProperties.$appPermissions("project").manage || 0;
const view_widget_sidebar: Ref<boolean> = ref(false);
const sidebar_refresh: Ref<boolean> = ref(false);
const widgetListData = ref();
const view_widgets: Ref<boolean> = ref(false);
let widget_lists = reactive([]);
let dw_id:any = reactive([]);
let summary = reactive({});
let dragged_widgets = reactive([]);
let un_dragged_widgets = reactive([]);
let userData: any = reactive({});
onMounted(() => {
  getSummary();
});

function toggleWidgetSidebar() {
  view_widget_sidebar.value = !view_widget_sidebar.value;
}
async function getAllWidgets() {
  await appContext.config.globalProperties.app_service.getData(
    "widgets",
    null,
    (response: any, error: any) => {
      if (response) {
        widget_lists = response.data;
        filterWidgets();
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    },
    false
  );
}
async function getSummary() {
  await appContext.config.globalProperties.app_service.getData(
    `summary?filter_mode=${widgetData.value}`,
    null,
    (response: any) => {
      if (response) {
        summary = response;
        userData = store.getters["setting/getUserDetail"];
        getAllWidgets();
      }
    },
    false
  );
}

function filterWidgets() {
  view_widgets.value = false;
  if (userData.preferences) {
    dragged_widgets = userData.preferences.widgets
      ? appContext.config.globalProperties.app_service.parseJSON(
          userData.preferences.widgets,
          (data: any) => {
            return data;
          }
        )
      : [];
  }
  updateWidgets();
  const my_widgets = [] as any;
  widget_lists.forEach((widget: any) => {
    if (!dw_id.includes(widget.id)) {
      my_widgets.push(widget);
    }
  });
  un_dragged_widgets = my_widgets;
  view_widgets.value = true;
}

function removeDragged(el: any) {
  dragged_widgets = dragged_widgets.filter((widget: any) => el.id != widget.id);
  userData.preferences.widgets = dragged_widgets;
  updateWidgets();
  updatePreference();
}

function updateWidgets() {
  dw_id = dragged_widgets.map((dw: any) => { return dw.id; });
  sidebar_refresh.value = true;
  setTimeout(()=>{
    sidebar_refresh.value = false;
  },50)
}

function updatePreference() {
  appContext.config.globalProperties.app_service.updateUserPreference(
    appContext.config.globalProperties,
    "widgets",
    dragged_widgets,
    (response: any) => {
      if (response) {
        userData.preferences.widgets = dragged_widgets;
        filterWidgets();
      }
    }
  );
}
</script>