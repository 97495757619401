import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  id: "document-table",
  class: "p-2"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "grid" }
const _hoisted_4 = { class: "col text-center" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "overlap-icon shared-icon fas fa-share-alt"
}
const _hoisted_7 = {
  key: 1,
  class: "overlap-icon starred-icon fas fa-star"
}
const _hoisted_8 = {
  key: 0,
  class: "field grid"
}
const _hoisted_9 = { class: "col white-space-nowrap" }
const _hoisted_10 = { class: "field grid" }
const _hoisted_11 = { class: "col white-space-nowrap" }
const _hoisted_12 = { class: "field grid" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "field grid" }
const _hoisted_15 = { class: "col" }
const _hoisted_16 = { class: "field grid" }
const _hoisted_17 = { class: "col" }
const _hoisted_18 = {
  key: 1,
  class: "field grid"
}
const _hoisted_19 = { class: "col" }
const _hoisted_20 = {
  key: 2,
  class: "field grid"
}
const _hoisted_21 = { class: "col" }
const _hoisted_22 = {
  key: 3,
  class: "field grid"
}
const _hoisted_23 = { class: "col" }
const _hoisted_24 = { class: "field grid" }
const _hoisted_25 = { class: "col" }
const _hoisted_26 = {
  key: 2,
  class: "text-center my-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_error_message = _resolveComponent("app-error-message")!
  const _component_progress_spinner = _resolveComponent("progress-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!Object.keys(_ctx.doc).length)
      ? (_openBlock(), _createBlock(_component_app_error_message, {
          key: 0,
          message: "Select a project, folder or file to view its details",
          page_icon: "fa fa-file",
          hide_button: "",
          hide_page_type: ""
        }))
      : _createCommentVNode("", true),
    (!_ctx.self.loading && Object.keys(_ctx.doc).length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", {
                class: _normalizeClass(["document-icon", [_ctx.doc.type == 'file' ? 'file' : _ctx.appContext.config.globalProperties.$getContentType(_ctx.doc)]])
              }, [
                _createElementVNode("span", {
                  innerHTML: _ctx.appContext.config.globalProperties.$getIcon(_ctx.doc)
                }, null, 8, _hoisted_5),
                (_ctx.doc.shared)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6))
                  : _createCommentVNode("", true),
                (_ctx.doc.starred)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7))
                  : _createCommentVNode("", true)
              ], 2)
            ])
          ]),
          (_ctx.view_parent && _ctx.doc.parent_project && _ctx.doc.type != 'project')
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _cache[0] || (_cache[0] = _createElementVNode("label", { class: "col-fixed" }, "Parent Project:", -1)),
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.doc.parent_project.name), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_10, [
            _cache[1] || (_cache[1] = _createElementVNode("label", { class: "col-fixed" }, "Title:", -1)),
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.doc.name), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[2] || (_cache[2] = _createElementVNode("label", { class: "col-fixed" }, "Type:", -1)),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.capitalize(_ctx.doc.type)), 1)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[3] || (_cache[3] = _createElementVNode("label", { class: "col-fixed" }, "Project Url:", -1)),
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.doc.slug_url), 1)
          ]),
          _createElementVNode("div", _hoisted_16, [
            _cache[4] || (_cache[4] = _createElementVNode("label", { class: "col-fixed" }, "Owner:", -1)),
            _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.doc.owner.name ? _ctx.doc.owner.name : _ctx.doc.owner), 1)
          ]),
          (_ctx.doc.type != 'file' && !_ctx.self.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _cache[5] || (_cache[5] = _createElementVNode("label", { class: "col-fixed" }, "Folders:", -1)),
                _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.self.doc_detail.folder_count), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.doc.type != 'file' && !_ctx.self.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _cache[6] || (_cache[6] = _createElementVNode("label", { class: "col-fixed" }, "Files:", -1)),
                _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.self.doc_detail.file_count) + " (" + _toDisplayString(_ctx.appContext.config.globalProperties.$getFileSize(_ctx.self.doc_detail.file_size)) + ") ", 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.doc.type == 'file' && !_ctx.self.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _cache[7] || (_cache[7] = _createElementVNode("label", { class: "col-fixed" }, "Size:", -1)),
                _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.appContext.config.globalProperties.$getFileSize(_ctx.self.doc_detail.file_size)), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_24, [
            _cache[8] || (_cache[8] = _createElementVNode("label", { class: "col-fixed" }, "Created:", -1)),
            _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.appContext.config.globalProperties.$dayMonthDateYear(_ctx.doc.created_at)), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.self.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
          _createVNode(_component_progress_spinner, {
            style: {"width":"20px","height":"20px"},
            strokeWidth: "5",
            animationDuration: ".5s",
            "aria-label": "Loading"
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}