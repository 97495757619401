<template>
  <div id="document-table" class="p-2">
    <app-error-message
      v-if="!Object.keys(doc).length"
      message="Select a project, folder or file to view its details"
      page_icon="fa fa-file"
      hide_button
      hide_page_type
    />
    <div v-if="!self.loading && Object.keys(doc).length">
      <div class="grid">
        <div class="col text-center">
          <span
            class="document-icon"
            :class="[doc.type == 'file' ? 'file' : appContext.config.globalProperties.$getContentType(doc)]"
          >
            <span v-html="appContext.config.globalProperties.$getIcon(doc)"></span>
            <span
              v-if="doc.shared"
              class="overlap-icon shared-icon fas fa-share-alt"
            ></span>
            <span
              v-if="doc.starred"
              class="overlap-icon starred-icon fas fa-star"
            ></span>
          </span>
        </div>
      </div>
      <div v-if="view_parent && doc.parent_project && doc.type != 'project'" class="field grid">
        <label class="col-fixed">Parent Project:</label>
        <div class="col white-space-nowrap">
          {{ doc.parent_project.name }}
        </div>
      </div>
      <div class="field grid">
        <label class="col-fixed">Title:</label>
        <div class="col white-space-nowrap">
          {{ doc.name }}
        </div>
      </div>
      <div class="field grid">
        <label class="col-fixed">Type:</label>
        <div class="col">
          {{ capitalize(doc.type) }}
        </div>
      </div>
      <div class="field grid">
        <label class="col-fixed">Project Url:</label>
        <div class="col">
          {{ doc.slug_url }}
        </div>
      </div>
      <div class="field grid">
        <label class="col-fixed">Owner:</label>
        <div class="col">
          {{ doc.owner.name ? doc.owner.name : doc.owner }}
        </div>
      </div>

      <div v-if="doc.type != 'file' && !self.loading" class="field grid">
        <label class="col-fixed">Folders:</label>
        <div class="col">
          {{ self.doc_detail.folder_count }}
        </div>
      </div>
      <div v-if="doc.type != 'file' && !self.loading" class="field grid">
        <label class="col-fixed">Files:</label>
        <div class="col">
          {{ self.doc_detail.file_count }} ({{ appContext.config.globalProperties.$getFileSize(self.doc_detail.file_size) }})
        </div>
      </div>
      <div v-if="doc.type == 'file' && !self.loading" class="field grid">
        <label class="col-fixed">Size:</label>
        <div class="col">
          {{
            appContext.config.globalProperties.$getFileSize(self.doc_detail.file_size)
          }}
        </div>
      </div>
      <div class="field grid">
        <label class="col-fixed">Created:</label>
        <div class="col">
          {{
            appContext.config.globalProperties.$dayMonthDateYear(doc.created_at)
          }}
        </div>
      </div>
    </div>
    <div v-if="self.loading" class="text-center my-4">
      <progress-spinner
        style="width: 20px; height: 20px"
        strokeWidth="5"
        animationDuration=".5s"
        aria-label="Loading"
      />
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  capitalize,
  getCurrentInstance,
  onMounted,
  reactive,
  watch,
} from "vue";
export default defineComponent({
  name: "ActivitiesDetail",
  props: {
    doc: {
      type: Object,
      default: () => {
        return {};
      },
    },
    view_parent: {
      type:Boolean,
      default:false
    }
  },
  setup(props:any) {
    const { appContext }  = getCurrentInstance() as any;
    const self = reactive({
      doc_detail: {} as any,
      loading: true,
    });
    
    onMounted(() => {
      getDocAggressions();
    });

    watch(
      () => props.doc,
      () => {
        getDocAggressions();
      }
    );

    function getDocAggressions() {
      if (Object.keys(props.doc).length) {
        self.loading = true;
        appContext.config.globalProperties.app_service.getData(
          `${props.doc.type == "project" ? "projects" : "media"}/${props.doc.id}/aggregations`,
          null,
          (response: any, error: any) => {
            if (response) {
              self.doc_detail = response;
              self.loading = false;
            }
            if (error) {
              appContext.config.globalProperties.$toastMessage(
                appContext.config.globalProperties.$getErrorResponse(error)
              );
            }
          },
          false
        );
      } else {
        self.loading = false;
      }
    }
    return {
      appContext,
      self,
      capitalize,
      getDocAggressions,
    };
  },
});
</script>