<template>
  <div>
    <AppToolbar
      permission="user"
      :breadcrumb_home="self.breadcrumbItems"
      :options="self.toolbarOptions"
      :loading="self.loading"
    />
    <div class="the-content">
      <Card>
        <template #title>
          Remember csv file can be of any number of column and rows but HEADER must have one column name labeled as Email or email.
        </template>
        <template #content>
          <Toolbar>
            <template #start>
              Select a file
            </template>
            <template #end>
              <FileUpload ref="fileUpload" mode="basic" name="demo[]" @select="onFileSelect" @remove="onFileRemove" @clear="onFileRemove" accept="text/csv" />
            </template>
          </Toolbar>
          <div class="message" v-if="self.partial_success">
            <br/>
            <h4>Some email address are not found in the database.</h4>
            <div class="error">
              <ul>
                <li v-for="(item, index) in self.email_not_found" :key="index">
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="flex align-items-center justify-content-end mt-2">
            <Button
              type="submit"
              label="Upload File"
              :loading="self.disabling_users"
              @click.prevent="uploadDisableUsers"
              class="button-primary"
            />
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, getCurrentInstance } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "disableUsers",
  setup() {
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      breadcrumbItems: {
        icon: "fas fa-user-times mr-1",
        label: "Disable Users",
        to: { name: "disableUsers" },
      },
      loading:true,
      store: useStore(),
      toolbarOptions: {},
      api_url: "users/disable/file",
      file: null,
      disabling_users: false,
      partial_success: false,
      email_not_found: [],
    });

    onMounted(() => {
      self.loading = false;
    });

    function onFileSelect(event) {
      self.file = event.files[0]
    }

    function onFileRemove() {
      self.file = null
    }

    function uploadDisableUsers() {
      if (!self.file){
        const error = {
          response: {
            data: {
              message: "Please select a file to upload.",
            },
            status: 400, // Example HTTP status
          },
        };
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
        return
      }
      self.disabling_users = true;
      const formData = new FormData();
      formData.append("file", self.file);
      appContext.config.globalProperties.app_service.fileUploadData(
        self.api_url,
        formData,
        (response: any, error: any) => {
          if (response) {
            appContext.config.globalProperties.$toastMessage(response);
            if (response.data.email_not_found.length){
              self.partial_success = true;
              self.email_not_found = response.data.email_not_found;
            }
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
          self.disabling_users = false;
        }
      );
    }

    return {
      self,
      onFileSelect,
      onFileRemove,
      uploadDisableUsers,
    };
  },
});
</script>